<template>
  <div v-if="user && user.rowStatus !== 'E'" class="text-center">
    Actualmente su usuario está desactivado
  </div>
  <div v-else>
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <!-- Page Header - Title -->
      <d-col col sm="4" class="text-center text-sm-left mb-0 mb-sm-0">
        <h3 class="page-title">Nuevo Estudio</h3>
      </d-col>
    </d-row>
    <d-row v-if="currentCountry">
      <div
        v-if="currentCountry.countryUmbrellaSetting.length"
        :class="
          user.roles.find((r) => r.roleType == 'pre-screening')
            ? 'container-cstm-3'
            : 'container-cstm'
        "
      >
        <div class="btn-group" role="group">
          <input
            id="btnradio1"
            v-model="current_item.typeOfStudy"
            type="radio"
            class="btn-check"
            name="btnradio"
            autocomplete="off"
            :value="'bmks'"
            @input="resetStudyValues"
          />
          <label class="btn btn-outline-primary text-white" for="btnradio1"
            >Biomarcadores de la industria
          </label>

          <input
            id="btnradio2"
            v-model="current_item.typeOfStudy"
            type="radio"
            class="btn-check"
            name="btnradio"
            autocomplete="off"
            :value="'NGS'"
            @input="
              resetStudyValues()
              loadUmbrellaInfo()
            "
          />
          <label class="btn btn-outline-primary text-white" for="btnradio2"
            >Paneles de NGS</label
          >
          <div v-if="user.roles.find((r) => r.roleType == 'pre-screening')">
            <input
              id="btnradio3"
              v-model="current_item.typeOfStudy"
              type="radio"
              class="btn-check"
              name="btnradio"
              autocomplete="off"
              :value="'PSP'"
              @input="
                resetStudyValues()
                loadUmbrellaInfo()
              "
            />
            <label class="btn btn-outline-primary text-white" for="btnradio3"
              >Pre-screening program</label
            >
          </div>
        </div>
      </div>
      <hr
        v-if="currentCountry.countryUmbrellaSetting.length"
        size="0"
        width="0"
      />
    </d-row>
    <hr />
    <d-row v-if="current_item.typeOfStudy == 'bmks'">
      <d-col sm="12">
        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Solicitud de Estudio</h6>
          </d-card-header>
          <d-card-body>
            <div class="form-group">
              <form-label required> Médico Solicitante </form-label>
              <d-form-select
                v-model="current_item.doctor_id"
                class="doctor"
                :disabled="options.doctors.length <= 1"
                :options="options.doctors"
              >
                <option :value="null" disabled>Seleccione un doctor</option>
              </d-form-select>
            </div>
            <div v-if="current_item.doctor_id" class="form-group">
              <form-label required> Tumor Primario </form-label>
              <d-form-select
                v-model="current_item.primaryTumorId"
                :options="primaryTumorOptions"
                placeholder="Por favor elija uno para continuar"
                class="primary-tumor"
                @input="
                  availableBiomarkers = setAvailableBiomarkers()
                  diagnosisCurrentSelected()
                "
                @change="($event) => primaryTumorChanged($event)"
              >
                <option :value="null" disabled>
                  Seleccione un tipo de tumor
                </option>
              </d-form-select>
            </div>
            <div v-if="current_item.primaryTumorId" class="form-group">
              <form-label required> Tipo de muestra </form-label>
              <d-form-select
                id="sampleTypeSelect"
                v-model="current_item.sampleTypeId"
                :disabled="availableSampleTypes.length === 1"
                :options="availableSampleTypes"
                class="sample-type"
                @input="availableBiomarkers = setAvailableBiomarkers()"
                @change="
                  diagnosisCurrentSelected()
                  current_item.patient.diagnosis = null
                "
              >
                <option :value="null" disabled>
                  Seleccione un tipo de muestra
                </option>
              </d-form-select>
            </div>

            <!-- Diagnosis field custom -->

            <div
              v-if="
                current_item.sampleTypeId !== null &&
                (currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code ===
                    TUMOR_TYPES.GASTROESOPHAGEAL) &&
                currentCountryName === 'Argentina'
              "
              class="form-group"
            >
              <form-label
                :required="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL
                "
                name="diagnostic_field"
                >Diagnóstico</form-label
              >
              <d-form-select
                v-if="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL
                "
                v-model="current_item.patient.diagnosis"
                :options="diagnosisOptions"
                name="diagnosisOptionsCustomSelect"
                @change="diagnosisCurrentSelected()"
              >
                <option :value="null" disabled>
                  Seleccione un diagnóstico
                </option>
              </d-form-select>
              <hr size="0" width="0" />
              <test-picker
                v-if="
                  current_item.patient.diagnosis !== null &&
                  (currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                    currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                    currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                    currentlySelectedTumor.code ===
                      TUMOR_TYPES.GASTROESOPHAGEAL) &&
                  currentCountryName === 'Argentina'
                "
                name="testPickerEsophagus"
                :tests="customAvailableBiomarkers"
                :unavailable-tests="biomarkersTestsCaps"
                :primary-tumor-id="currentlySelectedTumor.id"
                @tests-changed="(tests) => (current_item.studyTests = tests)"
                @remove-test="
                  (test) =>
                    current_item.studyTests.splice(
                      current_item.studyTests.findIndex(
                        (t) => t.testCode === test.testCode
                      ),
                      1
                    )
                "
                @add-test="
                  (test) =>
                    current_item.studyTests.splice(
                      current_item.studyTests.findIndex(
                        (t) => t.testCode === test.testCode
                      ),
                      0,
                      test
                    )
                "
              />
              <div v-if="checkSquamosousCarcinome() == true">
                <p
                  v-if="customAvailableBiomarkers.length == 0"
                  style="font-size: 16px; color: #c4183c"
                >
                  No hay biomarcadores configurados para este tipo de muestra y
                  diagnostico.
                </p>
              </div>
              <div v-if="isLungArgentina">
                <p
                  v-if="autoGenerationOFAAdvice"
                  id="autoGenerationOFAAdvice"
                  class="text-accent"
                >
                  {{ autoGenerationOFAAdvice.content.text }}
                </p>
                <p
                  v-if="PSPIsDisabledForThisUser"
                  id="PSPIsDisabledAdvice"
                  class="text-accent"
                >
                  {{ PSPIsDisabledAdvice.content.text }}
                </p>
              </div>
            </div>

            <div v-else-if="current_item.sampleTypeId !== null">
              <test-picker
                :required="current_item.sampleTypeId"
                :tests="availableBiomarkers"
                :unavailable-tests="biomarkersTestsCaps"
                :primary-tumor-id="currentlySelectedTumor.id"
                @tests-changed="(tests) => (current_item.studyTests = tests)"
                @remove-test="
                  (test) =>
                    current_item.studyTests.splice(
                      current_item.studyTests.findIndex(
                        (t) => t.testCode === test.testCode
                      ),
                      1
                    )
                "
                @add-test="
                  (test) =>
                    current_item.studyTests.splice(
                      current_item.studyTests.findIndex(
                        (t) => t.testCode === test.testCode
                      ),
                      0,
                      test
                    )
                "
              />

              <div v-if="isPatientPharmaSelected">
                <p
                  v-if="MMRColonAdvice"
                  id="autoGenerationOFAAdvice"
                  class="text-danger"
                >
                  {{ MMRColonAdvice.content.text }}
                </p>
              </div>
            </div>

            <template
              v-if="
                current_item.studyTests.every(
                  ({ pharmaId }) => pharmaId !== null
                )
              "
            >
              <div
                v-if="current_item.primaryTumorId && requiredBiomakerEnvelope"
                class="envelope"
              >
                <form-label required> Código del sobre </form-label>
                <d-input
                  v-model="current_item.envelope_code"
                  name="envelope_code"
                  placeholder="Ej: BE42EXT"
                  class="uppercase large-form-text"
                  @input="
                    checkDiagnostico(unfilledFields)
                    checkDisabledPickup(unfilledFields)
                    if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                />
                <d-form-checkbox
                  v-if="showCheckboxForBioEnvelope"
                  id="has_envelope_code"
                  v-model="current_item.dont_have_envelope_code"
                  @change="
                    if (current_item.dont_have_envelope_code == true) {
                      checkDiagnostico(unfilledFields)
                      checkDisabledPickup(unfilledFields)
                    } else if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                >
                  No tengo número de sobre
                </d-form-checkbox>
              </div>

              <study-envelope-code
                v-for="pharma in pharmasWithCustomEnvelope"
                :key="pharma.id"
                :pharma="pharma"
                :value="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).pharmaEnvelopeCode
                "
                :tumor="current_item.primaryTumorId"
                :country="currentCountry"
                :biomarkers-tests="
                  current_item.studyTests.filter(
                    (test) =>
                      test.pharmaId === pharma.id &&
                      (pharma.biomarkerIds != undefined
                        ? pharma.biomarkerIds.includes(test.testId)
                        : pharma.biomarkerId === test.testId)
                  )
                "
                :dont-have-pharma-envelope-code="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).dontHavePharmaEnvelopeCode
                "
                @input="setStudyTestCustomEnvelopeCode"
              />
              <div
                v-if="
                  current_item.studyTests.every(
                    ({ pharmaId }) => pharmaId !== null
                  ) &&
                  checkRASFromAmgen &&
                  currentCountryName === 'Argentina' &&
                  user.relationType == 'doctor'
                "
              >
                <hr />
                <a @click="() => (showMerckModal = true)">
                  Para realizar la solicitud de testeo por medio de la
                  plataforma RAS de Merck, por favor haga click aquí:<img
                    src="../assets/images/logos/RAS APP-01.png"
                    alt="RAS"
                    style="height: 80px; weigth: 80px"
                /></a>
              </div>
            </template>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
    <!-- NGS -->
    <d-row v-else-if="current_item.typeOfStudy == 'NGS'">
      <d-col sm="12">
        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Solicitud de Estudio de Panel Multi-génico</h6>
          </d-card-header>
          <d-card-body>
            <div class="form-group">
              <form-label required> Médico Solicitante </form-label>
              <d-form-select
                v-model="current_item.doctor_id"
                class="doctor"
                :disabled="options.doctors.length <= 1"
                :options="options.doctors"
              >
                <option :value="null" disabled>Seleccione un doctor</option>
              </d-form-select>
            </div>
            <div
              v-if="
                current_item.doctor_id &&
                $store.state.studies.umbrellaSettings.length
              "
              class="form-group"
            >
              <form-label required> Tumor Primario </form-label>
              <d-form-select
                v-model="current_item.primaryTumorId"
                :options="primaryTumorOptionsForUmbrella"
                placeholder="Por favor elija uno para continuar"
                class="primary-tumor"
                @load="loadUmbrellaInfo()"
                @change="
                  primaryTumorChanged
                  resetStudyValuesWhenChangePrimaryTumor()
                "
                @input="availableBiomarkers = setAvailableBiomarkers()"
              >
                <option :value="null" disabled>
                  Seleccione un tipo de tumor
                </option>
              </d-form-select>
            </div>
            <div v-if="current_item.primaryTumorId" class="form-group">
              <form-label required> Tipo de muestra </form-label>
              <d-form-select
                id="sampleTypeSelect"
                v-model="current_item.sampleTypeId"
                :disabled="availableSampleTypesForUmbrellas.length === 1"
                :options="availableSampleTypesForUmbrellas"
                class="sample-type"
                @input="availableBiomarkers = setAvailableBiomarkers()"
              >
                <option :value="null" disabled>
                  Seleccione un tipo de muestra
                </option>
              </d-form-select>
            </div>
            <div
              v-if="
                current_item.sampleTypeId !== null &&
                (currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL)
              "
              class="form-group"
            >
              <form-label
                :required="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS
                "
                name="diagnostic_field"
                >Diagnóstico</form-label
              >
              <d-form-select
                v-if="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL
                "
                v-model="current_item.patient.diagnosis"
                :options="diagnosisOptions"
                name="diagnosisOptionsCustomSelect"
                @change="diagnosisCurrentSelected()"
              >
                <option :value="null" disabled>
                  Seleccione un diagnóstico
                </option>
              </d-form-select>
            </div>
            <div
              v-if="
                (current_item.sampleTypeId &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.LUNG &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.ESOPHAGUS &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.GASTRIC &&
                  currentlySelectedTumor.code !==
                    TUMOR_TYPES.GASTROESOPHAGEAL) ||
                (current_item.sampleTypeId &&
                  (currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                    currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                    currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                    currentlySelectedTumor.code ===
                      TUMOR_TYPES.GASTROESOPHAGEAL) &&
                  current_item.patient.diagnosis)
              "
            >
              <optimus-test-picker
                :required="current_item.sampleTypeId"
                :tests="umbrellaTestForThisPrimaryTumor"
                :primary-tumor-id="current_item.primaryTumorId"
                :sample-type-id="current_item.sampleTypeId"
                :is-only-n-g-s="false"
                @tests-changed="
                  (tests) => {
                    current_item.studyTests = tests
                  }
                "
                @set_umbrella_id="(id) => (current_item.umbrellaId = id)"
              />
              <div v-if="isPatientPharmaSelected">
                <p
                  v-if="MMRColonAdvice"
                  id="autoGenerationOFAAdvice"
                  class="text-danger"
                >
                  {{ MMRColonAdvice.content.text }}
                </p>
              </div>
            </div>
            <div
              v-if="
                current_item.studyTests.length > 0 &&
                current_item.studyTests.every(
                  ({ pharmaId }) => pharmaId !== null
                )
              "
            >
              <div
                v-if="current_item.primaryTumorId && !requiredUmbrellaEnvelope"
                class="envelope"
              >
                <form-label required> Código del sobre </form-label>
                <d-input
                  v-model="current_item.envelope_code"
                  name="envelope_code"
                  placeholder="Ej: BE42EXT"
                  class="uppercase large-form-text"
                  @input="
                    checkDiagnostico(unfilledFields)
                    checkDisabledPickup(unfilledFields)
                    if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                />
                <d-form-checkbox
                  v-if="showCheckboxForBioEnvelope"
                  id="has_envelope_code"
                  v-model="current_item.dont_have_envelope_code"
                  @change="
                    if (current_item.dont_have_envelope_code == true) {
                      checkDiagnostico(unfilledFields)
                      checkDisabledPickup(unfilledFields)
                    } else if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                >
                  No tengo número de sobre
                </d-form-checkbox>
              </div>
            </div>
            <div v-if="requiredUmbrellaEnvelope">
              <study-envelope-code
                v-for="pharma in pharmasWithCustomEnvelopeForNGS"
                :key="pharma.id"
                :pharma="pharma"
                :value="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).pharmaEnvelopeCode
                "
                :tumor="current_item.primaryTumorId"
                :country="currentCountry"
                :umbrella="
                  umbrellasEnabled.find((u) => u.id === current_item.umbrellaId)
                "
                :biomarkers-tests="
                  current_item.studyTests.filter(
                    (test) => test.pharmaId === pharma.id
                  )
                "
                :dont-have-pharma-envelope-code="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).dontHavePharmaEnvelopeCode
                "
                @input="setStudyTestCustomEnvelopeCode"
              />
            </div>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
    <!-- PSP -->
    <d-row v-else-if="current_item.typeOfStudy == 'PSP'">
      <d-col sm="12">
        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Solicitud de Estudio de Pre-screening Program</h6>
          </d-card-header>
          <d-card-body>
            <div class="form-group">
              <form-label required> Médico Solicitante </form-label>
              <d-form-select
                v-model="current_item.doctor_id"
                class="doctor"
                :disabled="options.doctors.length <= 1"
                :options="options.doctors"
              >
                <option :value="null" disabled>Seleccione un doctor</option>
              </d-form-select>
            </div>
            <div
              v-if="
                current_item.doctor_id &&
                $store.state.studies.umbrellaSettings.length
              "
              class="form-group"
            >
              <form-label required> Tumor Primario </form-label>
              <d-form-select
                v-model="current_item.primaryTumorId"
                :options="primaryTumorOptionsForPreScreening"
                placeholder="Por favor elija uno para continuar"
                class="primary-tumor"
                @change="
                  primaryTumorChanged
                  resetStudyValuesWhenChangePrimaryTumor()
                "
                @input="availableBiomarkers = setAvailableBiomarkers()"
              >
                <option :value="null" disabled>
                  Seleccione un tipo de tumor
                </option>
              </d-form-select>
            </div>
            <p
              v-if="PSPIsDisabledForThisUser"
              id="PSPIsDisabledAdvice"
              class="text-accent"
            >
              {{ PSPIsDisabledAdvice.content.text }}
            </p>
            <div v-if="current_item.primaryTumorId" class="form-group">
              <form-label required> Tipo de muestra </form-label>
              <d-form-select
                id="sampleTypeSelect"
                v-model="current_item.sampleTypeId"
                :disabled="availableSampleTypesForPreScreening.length === 1"
                :options="availableSampleTypesForPreScreening"
                class="sample-type"
                @input="availableBiomarkers = setAvailableBiomarkers()"
              >
                <option :value="null" disabled>
                  Seleccione un tipo de muestra
                </option>
              </d-form-select>
            </div>
            <div
              v-if="
                current_item.sampleTypeId !== null &&
                (currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL)
              "
              class="form-group"
            >
              <form-label
                :required="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS
                "
                name="diagnostic_field"
                >Diagnóstico</form-label
              >
              <d-form-select
                v-if="
                  currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                  currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                  currentlySelectedTumor.code === TUMOR_TYPES.GASTROESOPHAGEAL
                "
                v-model="current_item.patient.diagnosis"
                :options="diagnosisOptions"
                name="diagnosisOptionsCustomSelect"
                @change="diagnosisCurrentSelected()"
              >
                <option :value="null" disabled>
                  Seleccione un diagnóstico
                </option>
              </d-form-select>
            </div>
            <div
              v-if="
                (current_item.sampleTypeId &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.LUNG &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.ESOPHAGUS &&
                  currentlySelectedTumor.code !== TUMOR_TYPES.GASTRIC &&
                  currentlySelectedTumor.code !==
                    TUMOR_TYPES.GASTROESOPHAGEAL) ||
                (current_item.sampleTypeId &&
                  (currentlySelectedTumor.code === TUMOR_TYPES.LUNG ||
                    currentlySelectedTumor.code === TUMOR_TYPES.ESOPHAGUS ||
                    currentlySelectedTumor.code === TUMOR_TYPES.GASTRIC ||
                    currentlySelectedTumor.code ===
                      TUMOR_TYPES.GASTROESOPHAGEAL) &&
                  current_item.patient.diagnosis)
              "
            >
              <optimus-test-picker
                :required="current_item.sampleTypeId"
                :tests="preScreeningTestForThisPrimaryTumor"
                :primary-tumor-id="current_item.primaryTumorId"
                :sample-type-id="current_item.sampleTypeId"
                :unavailable-tests="preScreeningCapsForThisUser"
                :is-only-n-g-s="true"
                @tests-changed="
                  (tests) => {
                    current_item.studyTests = tests
                  }
                "
                @set_umbrella_id="(id) => (current_item.umbrellaId = id)"
              />
              <div v-if="isPatientPharmaSelected">
                <p
                  v-if="MMRColonAdvice"
                  id="autoGenerationOFAAdvice"
                  class="text-danger"
                >
                  {{ MMRColonAdvice.content.text }}
                </p>
              </div>
            </div>
            <div
              v-if="
                current_item.studyTests.length > 0 &&
                current_item.studyTests.every(
                  ({ pharmaId }) => pharmaId !== null
                )
              "
            >
              <div
                v-if="current_item.primaryTumorId && !requiredUmbrellaEnvelope"
                class="envelope"
              >
                <form-label required> Código del sobre </form-label>
                <d-input
                  v-model="current_item.envelope_code"
                  name="envelope_code"
                  placeholder="Ej: BE42EXT"
                  class="uppercase large-form-text"
                  @input="
                    checkDiagnostico(unfilledFields)
                    checkDisabledPickup(unfilledFields)
                    if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                />
                <d-form-checkbox
                  v-if="showCheckboxForBioEnvelope"
                  id="has_envelope_code"
                  v-model="current_item.dont_have_envelope_code"
                  @change="
                    if (current_item.dont_have_envelope_code == true) {
                      checkDiagnostico(unfilledFields)
                      checkDisabledPickup(unfilledFields)
                    } else if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    }
                  "
                >
                  No tengo número de sobre
                </d-form-checkbox>
              </div>
            </div>
            <div v-if="requiredUmbrellaEnvelope">
              <study-envelope-code
                v-for="pharma in pharmasWithCustomEnvelopeForNGS"
                :key="pharma.id"
                :pharma="pharma"
                :value="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).pharmaEnvelopeCode
                "
                :tumor="current_item.primaryTumorId"
                :country="currentCountry"
                :umbrella="
                  umbrellasEnabled.find((u) => u.id === current_item.umbrellaId)
                "
                :biomarkers-tests="
                  current_item.studyTests.filter(
                    (test) => test.pharmaId === pharma.id
                  )
                "
                :dont-have-pharma-envelope-code="
                  current_item.studyTests.find(
                    (test) => test.pharmaId === pharma.id
                  ).dontHavePharmaEnvelopeCode
                "
                @input="setStudyTestCustomEnvelopeCode"
              />
            </div>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>

    <d-row v-if="displayFullForm">
      <d-col sm="6">
        <d-card id="patient-info" class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Datos del Paciente</h6>
          </d-card-header>
          <d-card-body>
            <div class="form-row">
              <div class="form-group col-6">
                <form-label required> Nombres </form-label>
                <d-input
                  v-model="current_item.patient.first_name"
                  type="text"
                  placeholder="Nombres del paciente"
                  @change="$v.current_item.patient.first_name.$touch()"
                  @input="
                    if (
                      checkStringInAField(unfilledFields, 'Nombres') == true &&
                      checkInput('Nombres ') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Nombres'),
                        1
                      )
                    } else if (
                      checkStringInAField(unfilledFields, 'Nombres') == false &&
                      checkInput('Nombres ') == false
                    ) {
                      unfilledFields.push('Nombres')
                    } else if (
                      checkStringInAField(unfilledFields, 'Diagnóstico') ==
                        true &&
                      currentlySelectedTumor.code === TUMOR_TYPES.LUNG &&
                      currentCountryName === 'Argentina'
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Diagnóstico'),
                        1
                      )
                    } else if (
                      checkStringInAField(
                        unfilledFields,
                        'Documentación RAS Merck'
                      ) == false &&
                      checkInput('Documentación RAS Merck ') == false &&
                      checkRASFromMerck &&
                      (currentCountryName === 'México' ||
                        currentCountryName === 'Chile')
                    ) {
                      unfilledFields.push('Documentación RAS Merck')
                    } else if (
                      unfilledFields.includes('Estadio actual') &&
                      currentlySelectedTumor.code !== TUMOR_TYPES.BLADDER
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Estadio actual'),
                        1
                      )
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.first_name.$invalid &&
                    $v.current_item.patient.first_name.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group col-6">
                <form-label required> Apellidos </form-label>
                <d-input
                  v-model="current_item.patient.last_name"
                  type="text"
                  placeholder="Apellidos del paciente"
                  @change="$v.current_item.patient.last_name.$touch()"
                  @input="
                    if (
                      checkStringInAField(unfilledFields, 'Apellidos') ==
                        true &&
                      checkInput('Apellidos ') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Apellidos'),
                        1
                      )
                    } else if (
                      checkStringInAField(unfilledFields, 'Apellidos') ==
                        false &&
                      checkInput('Apellidos ') == false
                    ) {
                      unfilledFields.push('Apellidos')
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.last_name.$invalid &&
                    $v.current_item.patient.last_name.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <form-label
                  :required="
                    !current_item.studyTests.find(
                      (st) => st.pharmaId === pfizerAndinoPharma.id
                    )
                  "
                >
                  Fecha de Nacimiento
                </form-label>
                <easy-datepicker
                  @input="
                    (date) => {
                      current_item.patient.birth_date = date
                      if (
                        checkStringInAField(
                          unfilledFields,
                          'Fecha de Nacimiento'
                        ) == true
                      ) {
                        unfilledFields.splice(
                          unfilledFields.indexOf('Fecha de Nacimiento'),
                          1
                        )
                      }
                      $v.current_item.patient.birth_date.$touch()
                    }
                  "
                  @invalid-input="
                    ($event) => {
                      current_item.patient.birth_date = $event
                      if (
                        checkStringInAField(
                          unfilledFields,
                          'Fecha de Nacimiento'
                        ) == false
                      ) {
                        unfilledFields.push('Fecha de Nacimiento')
                      }
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.birth_date.$invalid &&
                    $v.current_item.patient.birth_date.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group col-6">
                <form-label required>
                  {{
                    currentCountryName === 'México'
                      ? 'RFC/CURP'
                      : 'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                  }}
                </form-label>
                <d-form-input
                  v-model="current_item.patient.identity_document"
                  type="text"
                  :placeholder="
                    currentCountryName === 'México'
                      ? 'RFC/CURP'
                      : 'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                  "
                  @input="
                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                      ) == true &&
                      (currentCountryName === 'México'
                        ? checkInput('RFC/CURP ') == true
                        : checkInput(
                            'Documento Nacional De Identidad/Cédula De Identidad/RUT '
                          ) == true)
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf(
                          'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                        ),
                        1
                      )
                    } else if (
                      checkStringInAField(
                        unfilledFields,
                        'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                      ) == false &&
                      (currentCountryName === 'México'
                        ? checkInput('RFC/CURP ') == false
                        : checkInput(
                            'Documento Nacional De Identidad/Cédula De Identidad/RUT '
                          ) == false)
                    ) {
                      unfilledFields.push(
                        'Documento Nacional De Identidad/Cédula De Identidad/RUT'
                      )
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.identity_document.$invalid &&
                    $v.current_item.patient.identity_document.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <form-label
                  :required="
                    !current_item.studyTests.find(
                      (st) => st.pharmaId === pfizerAndinoPharma.id
                    )
                  "
                >
                  Sexo
                </form-label>
                <d-form-select
                  id="gender-select"
                  v-model="current_item.patient.gender"
                  :options="genderOptions"
                  @change="
                    if (checkStringInAField(unfilledFields, 'Sexo') == true) {
                      unfilledFields.splice(unfilledFields.indexOf('Sexo'), 1)
                    }
                  "
                >
                  <option :value="null" disabled>Seleccione el sexo</option>
                </d-form-select>
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.gender.$invalid &&
                    $v.current_item.patient.gender.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group col-6">
                <form-label
                  :required="
                    !current_item.studyTests.find(
                      (st) => st.pharmaId === pfizerAndinoPharma.id
                    )
                  "
                  >{{
                    currentCountryName === 'México'
                      ? 'Seguro Médico'
                      : 'Cobertura Médica'
                  }}</form-label
                >
                <div v-if="insuranceDetailsConfigurated">
                  <d-input-group class="mb2">
                    <d-form-input
                      v-if="!current_item.patient.insurance_details"
                      v-model="searchInsuranceDetailsFieldText"
                      placeholder="Busque una cobertura médica"
                      :state="
                        current_item.patient.insurance_details == ''
                          ? 'invalid'
                          : true
                      "
                      @input="searchInsuranceDetails($event)"
                    />
                  </d-input-group>
                  <d-input-group>
                    <d-input
                      v-if="current_item.patient.insurance_details"
                      :value="
                        insuranceDetailsOptions.find(
                          (r) =>
                            r.value == current_item.patient.insurance_details
                        ).text
                      "
                      disabled
                    />

                    <d-input-group-addon
                      v-if="current_item.patient.insurance_details"
                      append
                    >
                      <d-button
                        class="btn-accent"
                        @click="
                          current_item.patient.insurance_details = ''
                          if (
                            checkStringInAField(
                              unfilledFields,
                              'Cobertura Médica'
                            ) == false
                          ) {
                            unfilledFields.push('Cobertura Médica')
                          }
                        "
                        ><i class="material-icons justify-content-center"
                          >close</i
                        ></d-button
                      >
                    </d-input-group-addon>
                  </d-input-group>
                  <d-list-group
                    v-if="insuranceDetailsSearchResult.length"
                    class="resultList"
                  >
                    <ul
                      v-for="result in insuranceDetailsSearchResult"
                      :key="result.value"
                    >
                      <a
                        @click="
                          () => (
                            (current_item.patient.insurance_details =
                              result.value),
                            (insuranceDetailsSearchResult = []),
                            (
                        checkStringInAField(unfilledFields,
                          'Cobertura Médica'
                        ) == true
                      ) ?
                        unfilledFields.splice(
                          unfilledFields.indexOf('Cobertura Médica'),
                          1
                        ) : null
                      ),
                        "
                      >
                        <d-list-group-item>{{ result.text }}</d-list-group-item>
                      </a>
                    </ul>
                  </d-list-group>
                </div>
                <div v-else>
                  <d-input
                    v-model="current_item.patient.insurance_details"
                    type="text"
                    name="insurance_details"
                    :placeholder="
                      currentCountryName === 'México'
                        ? 'Seguro Médico'
                        : 'Cobertura Médica'
                    "
                    @input="
                      if (
                        checkStringInAField(
                          unfilledFields,
                          'Cobertura Médica'
                        ) == true &&
                        checkInput('Cobertura Médica ') == true
                      ) {
                        unfilledFields.splice(
                          unfilledFields.indexOf('Cobertura Médica'),
                          1
                        )
                      } else if (
                        checkStringInAField(
                          unfilledFields,
                          'Cobertura Médica'
                        ) == false &&
                        checkInput('Cobertura Médica ') == false
                      ) {
                        unfilledFields.push('Cobertura Médica')
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div v-if="additionalFieldsEnabled" class="form-group col-12">
                <form-label required> Etnia </form-label>
                <d-form-select
                  id="ethinicity-select"
                  v-model="current_item.patient.ethinicity"
                  :options="ethinicityOptions"
                  @change="
                    if (unfilledFields.find((f) => f === 'Etnia')) {
                      unfilledFields.splice(unfilledFields.indexOf('Etnia'), 1)
                    }
                  "
                >
                  <option :value="null" disabled>Seleccione la etnia</option>
                </d-form-select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <form-label
                  :required="
                    !current_item.studyTests.find(
                      (st) => st.pharmaId === pfizerAndinoPharma.id
                    )
                  "
                  >Teléfono</form-label
                >
                <d-input
                  v-model="current_item.patient.telephone"
                  type="text"
                  name="telephone"
                  placeholder="Teléfono"
                  @input="
                    if (
                      checkStringInAField(unfilledFields, 'Teléfono') == true &&
                      checkInput('Teléfono ') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Teléfono'),
                        1
                      )
                    } else if (
                      checkStringInAField(unfilledFields, 'Teléfono') ==
                        false &&
                      checkInput('Teléfono ') == false
                    ) {
                      unfilledFields.push('Teléfono')
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.telephone.$invalid &&
                    $v.current_item.patient.telephone.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group col-6">
                <form-label>E-mail</form-label>
                <d-input
                  v-model="current_item.patient.email"
                  type="email"
                  name="email"
                  placeholder="E-mail"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <form-label required>
                  {{
                    currentCountryName === 'México'
                      ? 'Estado'
                      : 'Provincia o Estado'
                  }}</form-label
                >
                <d-form-select
                  v-if="administrativeAreasLevel1"
                  v-model="current_item.patient.administrative_area_level_1"
                  :options="administrativeAreasLevel1.content.areas"
                  @change="
                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Provincia o Estado'
                      ) == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Provincia o Estado'),
                        1
                      )
                    }
                  "
                />
                <d-input
                  v-else
                  v-model="current_item.patient.administrative_area_level_1"
                  type="text"
                  placeholder="Provincia o Estado"
                  @input="
                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Provincia o Estado'
                      ) == true &&
                      checkInput('Provincia o Estado ') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Provincia o Estado'),
                        1
                      )
                    } else if (
                      checkStringInAField(
                        unfilledFields,
                        'Provincia o Estado'
                      ) == false &&
                      checkInput('Provincia o Estado ') == false
                    ) {
                      unfilledFields.push('Provincia o Estado')
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.administrative_area_level_1
                      .$invalid &&
                    $v.current_item.patient.administrative_area_level_1.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group col-6">
                <form-label required> Localidad </form-label>
                <d-input
                  v-model="current_item.patient.administrative_area_level_2"
                  type="text"
                  placeholder="Localidad"
                  @input="
                    if (
                      checkStringInAField(unfilledFields, 'Localidad') ==
                        true &&
                      checkInput('Localidad ') == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Localidad'),
                        1
                      )
                    } else if (
                      checkStringInAField(unfilledFields, 'Localidad') ==
                        false &&
                      checkInput('Localidad ') == false
                    ) {
                      unfilledFields.push('Localidad')
                    }
                  "
                />
                <d-form-invalid-feedback
                  v-if="
                    $v.current_item.patient.administrative_area_level_2
                      .$invalid &&
                    $v.current_item.patient.administrative_area_level_2.$dirty
                  "
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
            </div>

            <multi-button-picker
              v-if="
                [
                  TUMOR_TYPES.LUNG,
                  TUMOR_TYPES.BLADDER,
                  TUMOR_TYPES.GASTRIC,
                  TUMOR_TYPES.ESOPHAGUS,
                  TUMOR_TYPES.GASTROESOPHAGEAL,
                ].includes(currentlySelectedTumor.code)
              "
              label="Condición de Fumador"
              :picker-options="smokerStatusOptions"
              :default-value="
                setNewDefaultValue(
                  smokerStatusOptions,
                  current_item.patient.smoker
                )
              "
              required
              @multi-button-picker-changed="updateSmokerCondition"
            />
            <hr />

            <div
              v-if="currentlySelectedTumor.code === TUMOR_TYPES.BLADDER"
              class="form-group"
            >
              <form-label>Terapias previas</form-label>
              <d-form-select
                v-model="current_item.patient.previous_therapies"
                :options="previousTherapiesOptions"
              >
                <option :value="null" disabled>
                  Seleccione la terapia previa
                </option>
              </d-form-select>
            </div>
            <div
              v-if="currentlySelectedTumor.code === TUMOR_TYPES.COLON"
              class="form-group"
            >
              <form-label>Localización del tumor</form-label>
              <d-form-select
                v-model="current_item.patient.diagnosis_location"
                :options="diagnosisLocationOptions"
              >
                <option :value="null" disabled>
                  Seleccione la ubicación del diagnóstico
                </option>
              </d-form-select>
            </div>
            <div
              v-if="
                [
                  TUMOR_TYPES.LUNG,
                  TUMOR_TYPES.GASTRIC,
                  TUMOR_TYPES.ESOPHAGUS,
                  TUMOR_TYPES.GASTROESOPHAGEAL,
                ].includes(currentlySelectedTumor.code) &&
                currentCountryName !== 'Argentina' &&
                current_item.typeOfStudy === 'bmks'
              "
              class="form-group"
            >
              <form-label
                :required="currentlySelectedTumor.code === TUMOR_TYPES.LUNG"
                name="diagnostic_field"
                >Diagnóstico</form-label
              >
              <d-form-select
                v-model="current_item.patient.diagnosis"
                :options="diagnosisOptions"
                name="diagnosisOptionsSelect"
                @change="
                  if (
                    checkStringInAField(unfilledFields, 'Diagnóstico') == true
                  ) {
                    unfilledFields.splice(
                      unfilledFields.indexOf('Diagnóstico'),
                      1
                    )
                  }
                "
              >
                <option :value="null" disabled>
                  Seleccione un diagnóstico
                </option>
              </d-form-select>
              <d-form-invalid-feedback
                v-if="
                  $v.current_item.patient.diagnosis.$invalid &&
                  $v.current_item.patient.diagnosis.$dirty
                "
              >
                Este campo es obligatorio
              </d-form-invalid-feedback>
            </div>
            <div class="form-row">
              <multi-button-picker
                col-4
                name="previousTesting"
                label="Testeo previo"
                :picker-options="previousTestingOptions"
                :default-value="
                  setNewDefaultValue(
                    previousTestingOptions,
                    current_item.patient.previous_testing
                  )
                "
                @multi-button-picker-changed="updatePreviousTesting"
              />
              <div class="form-group col-8">
                <form-label :required="additionalFieldsEnabled">{{
                  additionalFieldsEnabled
                    ? 'Estadío de Enfermedad al diagnóstico'
                    : 'Estadío de Enfermedad '
                }}</form-label>
                <d-form-select
                  id="diagnosis_stage-select"
                  v-model="current_item.patient.diagnosis_stage"
                  :options="diagnosisStageOptions"
                  @change="
                    $v.current_item.patient.diagnosis_stage.$touch()
                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Estadio de enfermedad'
                      ) == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Estadio de enfermedad'),
                        1
                      )
                    }
                  "
                >
                  <option :value="null" disabled>Seleccione un estadío</option>
                </d-form-select>
              </div>
            </div>

            <div class="form-row">
              <div v-if="additionalFieldsEnabled" class="form-group col-12">
                <form-label required
                  >Subtipo del cáncer al diagnóstico</form-label
                >
                <d-form-select
                  v-model="current_item.patient.cancer_subtype_at_diagnosis"
                  :options="cancerSubtypeAtDiagnosisOptions"
                  @change="
                    $v.current_item.patient.cancer_subtype_at_diagnosis.$touch()
                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Subtipo del cáncer al diagnóstico'
                      ) == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf(
                          'Subtipo del cáncer al diagnóstico'
                        ),
                        1
                      )
                    }
                  "
                >
                  <option :value="null" disabled>
                    Seleccione el subtipo de cancer
                  </option>
                </d-form-select>

                <d-input
                  v-if="
                    current_item.patient.cancer_subtype_at_diagnosis === 'other'
                  "
                  v-model="cancerSubtypeAtDiagnosisOtherInputField"
                  class="form-group col-6"
                  type="text"
                  placeholder="Ingrese otros subtipos de cancer"
                  :state="
                    cancerSubtypeAtDiagnosisOtherInputField == ''
                      ? 'invalid'
                      : true
                  "
                />
              </div>

              <div v-if="additionalFieldsEnabled" class="form-group col-12">
                <form-label required>Estadio actual</form-label>
                <d-form-select
                  v-model="current_item.patient.current_stage"
                  :options="currentStageOptions"
                  @change="
                    $v.current_item.patient.current_stage.$touch()
                    if (unfilledFields.includes('Estadio actual')) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Estadio actual'),
                        1
                      )
                    }
                  "
                >
                  <option :value="null" disabled>
                    Seleccione el estadio actual
                  </option>
                </d-form-select>
              </div>
              <div v-if="additionalFieldsEnabled">
                <form-label required
                  >Drogas usadas en tratamiento Actual</form-label
                >
                <d-form-checkbox
                  v-for="val in drugsUsedForActualTreatmentOptions"
                  :key="val.value"
                  v-model="current_item.patient.drugs_used_for_actual_treatment"
                  :value="val.value"
                >
                  {{ val.text }}
                </d-form-checkbox>
                <d-form-input
                  v-if="
                    current_item.patient.drugs_used_for_actual_treatment.includes(
                      'other'
                    )
                  "
                  v-model="otherDrugsUsedForActualTreatments"
                  placeholder="Ingrese otras drogas separadas por coma"
                />
                <d-form-checkbox
                  :checked="
                    current_item.patient.drugs_used_for_actual_treatment
                      .length == 0
                  "
                  disabled
                >
                  No drugs
                </d-form-checkbox>
              </div>
              <div v-if="actualTreatmentLineEnabled" class="form-group">
                <label>Línea de tratamiento actual</label>
                <d-form-select
                  v-model="current_item.patient.treatment_stage"
                  :options="treatmentStageOptions"
                  @change="
                    () => {
                      if (
                        unfilledFields.find(
                          (f) => f === 'Línea de tratamiento actual'
                        )
                      ) {
                        unfilledFields.splice(
                          unfilledFields.indexOf('Línea de tratamiento actual'),
                          1
                        )
                      }
                    }
                  "
                >
                  <option :value="null" disabled>
                    Seleccione una línea de tratamiento
                  </option>
                </d-form-select>
              </div>
              <div
                v-if="
                  additionalFieldsEnabled && previousTreatmentAndDrugsEnabled
                "
                class="form-group"
              >
                <form-label required
                  >Drogas usadas en tratamiento anterior</form-label
                >
                <d-form-checkbox
                  v-for="val in drugsUsedForPriorTreatmentOptions"
                  :key="val.value"
                  v-model="current_item.patient.drugs_used_for_prior_treatment"
                  :value="val.value"
                  :disabled="lockTreatmentAndDrugs"
                >
                  {{ val.text }}
                </d-form-checkbox>
                <d-form-input
                  v-if="
                    current_item.patient.drugs_used_for_prior_treatment.includes(
                      'other'
                    )
                  "
                  v-model="otherDrugsUsedForPiorTreatments"
                  placeholder="Ingrese otras drogas separadas por coma"
                />
                <d-form-checkbox
                  :checked="
                    current_item.patient.drugs_used_for_prior_treatment
                      .length == 0
                  "
                  disabled
                >
                  No drugs
                </d-form-checkbox>
              </div>
              <div
                v-if="
                  additionalFieldsEnabled && previousTreatmentAndDrugsEnabled
                "
                class="form-group col-12"
              >
                <form-label required>Tratamientos previos</form-label>
                <d-form-select
                  v-model="current_item.patient.previous_treatments"
                  :options="
                    previousNewTreatmentsOptions.length > 0
                      ? previousNewTreatmentsOptions
                      : previousTreatmentsOptions
                  "
                  :disabled="lockTreatmentAndDrugs"
                  @change="
                    $v.current_item.patient.previous_treatments.$touch()

                    if (
                      checkStringInAField(
                        unfilledFields,
                        'Tratamientos previos'
                      ) == true
                    ) {
                      unfilledFields.splice(
                        unfilledFields.indexOf('Tratamientos previos'),
                        1
                      )
                    }
                  "
                >
                  <option :value="null" disabled>
                    Seleccione el tratamiento previo
                  </option>
                </d-form-select>
              </div>
            </div>
          </d-card-body>
        </d-card>

        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Notificaciones</h6>
          </d-card-header>
          <d-card-body>
            <p>
              Una vez concluído el análisis, enviaremos los resultados a
              {{ email_notifications_to }}.
            </p>
            <div v-for="(email, i) in current_item.additional_emails" :key="i">
              <d-input
                v-model="current_item['additional_emails'][i]"
                placeholder="Ingrese un correo adicional de notificación"
                class="mb-2"
              />
            </div>

            <button
              v-if="current_item.additional_emails.length < 5"
              class="btn btn-outline-accent btn-pill"
              @click="current_item.additional_emails.push('')"
            >
              Agregar otro correo electrónico
            </button>
          </d-card-body>
        </d-card>
      </d-col>
      <d-col sm="6">
        <pickup-location-picker
          :key="current_item.doctor_id"
          v-model="current_item.pickup_location_id"
          :valid="isPickupLocationValid()"
          :doctor_id="current_item.doctor_id"
          :disabled="sampleIsLiquid"
          name="location_hour"
          @change="
            if (
              current_item.pickup_location_id === null &&
              checkStringInAField(unfilledFields, 'Comentarios Adicionales') ==
                false &&
              checkValidOtherField() !== 'valid'
            ) {
              unfilledFields.push('Comentarios Adicionales')
            } else if (
              checkStringInAField(unfilledFields, 'Punto de retiro') == true &&
              current_item.pickup_location_id !== undefined
            ) {
              unfilledFields.splice(
                unfilledFields.indexOf('Punto de retiro'),
                1
              )
            }
            ;($event) => (current_item.pickup_location_id = $event)
          "
          @input="
            if (
              checkStringInAField(unfilledFields, 'Punto de retiro') == true &&
              current_item.pickup_location_id !== undefined
            ) {
              unfilledFields.splice(
                unfilledFields.indexOf('Punto de retiro'),
                1
              )
            } else if (
              checkStringInAField(unfilledFields, 'Comentarios Adicionales') ==
              true
            ) {
              unfilledFields.splice(
                unfilledFields.indexOf('Comentarios Adicionales'),
                1
              )
            }
            ;($event) => (current_item.pickup_location_id = $event)
          "
          @reset="
            if (
              checkStringInAField(unfilledFields, 'Punto de retiro') == false
            ) {
              unfilledFields.push('Punto de retiro')
            } else if (
              checkStringInAField(unfilledFields, 'Punto de retiro') == true &&
              current_item.pickup_location_id !== undefined
            ) {
              unfilledFields.splice(
                unfilledFields.indexOf('Punto de retiro'),
                1
              )
            }
          "
        />

        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Horario de retiro</h6>
          </d-card-header>
          <d-card-body>
            <div
              v-if="
                $v.current_item.pickup_time_range.$invalid &&
                $v.current_item.pickup_time_range.$dirty
              "
            ></div>
            <multi-button-picker
              :picker-options="pickupTimeRangeOptions"
              :default-value="
                setNewDefaultValue(
                  pickupTimeRangeOptions,
                  current_item.pickup_time_range
                )
              "
              @multi-button-picker-changed="updateTimeRange"
              @change="
                if (
                  checkStringInAField(unfilledFields, 'Horario de retiro') ==
                  true
                ) {
                  unfilledFields.splice(
                    unfilledFields.indexOf('Horario de retiro'),
                    1
                  )
                }
              "
            />
          </d-card-body>
        </d-card>

        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Comentarios Adicionales</h6>
          </d-card-header>
          <d-card-body>
            <textarea
              id="additional-information-textarea"
              v-model="current_item.additional_information"
              class="form-control"
              rows="4"
              @blur="
                if (
                  current_item.pickup_location_id == null &&
                  checkStringInAField(
                    unfilledFields,
                    'Comentarios Adicionales'
                  ) == true &&
                  checkValidOtherField() === 'valid'
                ) {
                  unfilledFields.splice(
                    unfilledFields.indexOf('Comentarios Adicionales'),
                    1
                  )
                } else if (
                  current_item.pickup_location_id === null &&
                  checkStringInAField(
                    unfilledFields,
                    'Comentarios Adicionales'
                  ) == false &&
                  checkValidOtherField() !== 'valid'
                ) {
                  unfilledFields.push('Comentarios Adicionales')
                }
              "
              @input="
                if (
                  current_item.pickup_location_id == null &&
                  checkStringInAField(
                    unfilledFields,
                    'Comentarios Adicionales'
                  ) == true &&
                  checkValidOtherField() === 'valid'
                ) {
                  unfilledFields.splice(
                    unfilledFields.indexOf('Comentarios Adicionales'),
                    1
                  )
                } else if (
                  current_item.pickup_location_id === null &&
                  checkStringInAField(
                    unfilledFields,
                    'Comentarios Adicionales'
                  ) == false &&
                  checkValidOtherField() !== 'valid'
                ) {
                  unfilledFields.push('Comentarios Adicionales')
                }
              "
            />
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>

    <d-row v-if="displayFullForm" class="mb-8">
      <d-col sm="12">
        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Documentación</h6>
          </d-card-header>
          <d-card-body>
            <p class="pb-0 mb-2">
              Para realizar el estudio, incluya los siguientes ítems dentro del
              sobre:
            </p>
            <ul
              class="py-2 px-0"
              style="
                border: 1px solid #eee;
                list-style-type: none;
                font-size: 16px;
              "
            >
              <li
                v-for="(required_item, idx) in requiredItemsForEnvelope"
                :key="idx"
                class="px-3 py-1"
              >
                <i
                  class="material-icons"
                  style="font-size: 16px; color: #6943ff"
                  >{{ required_item.icon }}</i
                >&nbsp;&nbsp;&nbsp;{{ required_item.text }}
              </li>
            </ul>

            <div class="form-group">
              <d-form-checkbox
                id="has-pathology-report-checkbox"
                v-model="current_item.has_pathology_report"
                name="has-pathology-report"
                value="yes"
                @change="
                  if (
                    checkStringInAField(unfilledFields, 'Documentación') == true
                  ) {
                    unfilledFields.splice(
                      unfilledFields.indexOf('Documentación'),
                      1
                    )
                  } else {
                    unfilledFields.push('Documentación')
                  }
                "
              >
                Incluí en el sobre todos los items mencionados en la lista.
              </d-form-checkbox>

              <d-form-invalid-feedback
                v-if="
                  $v.current_item.has_pathology_report.$invalid &&
                  $v.current_item.has_pathology_report.$dirty
                "
              >
                La muestra no será procesada sin el informe de Anatomía
                Patológica correspondiente
              </d-form-invalid-feedback>
            </div>
            <div v-if="requiresConsentDeclaration()" class="form-group">
              <d-form-checkbox
                id="has-consent-checkbox"
                v-model="current_item.has_consent"
                name="has-consent"
                value="yes"
                @change="
                  if (
                    checkStringInAField(unfilledFields, 'Consentimientos') ==
                    true
                  ) {
                    unfilledFields.splice(
                      unfilledFields.indexOf('Consentimientos'),
                      1
                    )
                  } else {
                    unfilledFields.push('Consentimientos')
                  }
                "
              >
                Declaro haber obtenido y firmado los consentimientos informados
                del paciente y haberlos archivado debidamente, a fin de realizar
                los testeos. Manifiesto en forma libre mi consentimiento para el
                tratamiento de mis datos personales ("Datos Personales"),
                incluyendo datos sensibles, por parte de Biomakers con el fin de
                poder categorizarlos e incluso contactarme.
                <div v-for="item in informatedConsentsComputed" :key="item.id">
                  <a :href="item.path" :to="item.path" target="_blank">{{
                    item.label
                  }}</a>
                </div>
              </d-form-checkbox>
              <d-form-checkbox
                v-if="checkRASFromMerck && currentCountryName === 'México'"
                v-model="current_item.rasFromMerck"
                @change="
                  if (
                    checkStringInAField(
                      unfilledFields,
                      'Documentación RAS Merck'
                    ) == true
                  ) {
                    unfilledFields.splice(
                      unfilledFields.indexOf('Documentación RAS Merck'),
                      1
                    )
                  } else {
                    unfilledFields.push('Documentación RAS Merck')
                  }
                "
              >
                Declaro haber leído y consentido los T&C (Términos y
                condiciones) relacionados a RAS Test patrocinado por Merck
                <div>
                  <a
                    style="color: #6943ff"
                    target="_blank"
                    href="https://rasmerck.com/assets/files/terms-mx-pluton.pdf"
                    >Click aquí para leer los T&C</a
                  >
                </div>
              </d-form-checkbox>
              <d-form-checkbox
                v-if="checkRASFromMerck && currentCountryName === 'Chile'"
                v-model="current_item.rasFromMerck"
                @change="
                  if (
                    checkStringInAField(
                      unfilledFields,
                      'Documentación RAS Merck'
                    ) == true
                  ) {
                    unfilledFields.splice(
                      unfilledFields.indexOf('Documentación RAS Merck'),
                      1
                    )
                  } else {
                    unfilledFields.push('Documentación RAS Merck')
                  }
                "
              >
                Declaro haber leído y consentido los T&C (Términos y
                condiciones) relacionados a RAS Test patrocinado por Merck
                <div>
                  <a
                    style="color: #6943ff"
                    target="_blank"
                    href="https://rasmerck.com/assets/files/terms-cl-pluton.pdf"
                    >Click aquí para leer los T&C</a
                  >
                </div>
              </d-form-checkbox>
            </div>
            <div v-if="!can_send_form">
              <p style="font-size: 16px">
                Por favor, complete los siguientes campos para poder enviar el
                estudio:
              </p>
              <div class="box" color="#fad7de">
                <ul>
                  <li
                    v-for="(item, index) in unfilledFields"
                    :key="index"
                    style="font-size: 16px; color: #c4183c"
                  >
                    {{ unfilledFields[(item, index)] }}
                  </li>
                </ul>
              </div>
            </div>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>

    <d-row v-if="displayFullForm" class="mb-8">
      <d-col class="text-center mb-12">
        <button
          :disabled="!can_send_form"
          class="btn btn-accent btn-lg btn-pill d-block w-100 mb-12"
          @click.once="send"
        >
          Enviar Pedido
        </button>
      </d-col>
    </d-row>

    <d-modal v-if="showModal">
      <d-modal-body class="p-4"><span v-html="msg" /></d-modal-body>
      <d-modal-footer class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          @click="() => $router.replace({ name: NAMED_ROUTES.STUDIES })"
        >
          Cerrar
        </button>
      </d-modal-footer>
    </d-modal>
    <d-modal v-if="showMerckModal">
      <d-modal-body
        >Atención! usted va a salir de Biomakers y envíar a la app RAS-Merck sus
        datos personales
      </d-modal-body>
      <d-modal-footer>
        <button
          type="button"
          class="btn btn-primary btn-accent"
          data-dismiss="modal"
          @click="sendDataToMerck()"
        >
          Enviar
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="() => (showMerckModal = false)"
        >
          Atras
        </button></d-modal-footer
      >
    </d-modal>
  </div>
</template>

<script>
import { es } from 'vuejs-datepicker/dist/locale'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters } from 'vuex'

import { getOptionsFor } from '@/utils'
import { TUMOR_TYPES, ROW_STATUS } from '@/utils/constants'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import FormLabel from '@/components/Form/Label'
import TestPicker from '@/components/Form/TestPicker'
import OptimusTestPicker from '@/components/Form/OptimusTestPicker.vue'
import MultiButtonPicker from '@/components/MultiButtonPicker'
import StudyEnvelopeCode from '@/components/StudyEnvelopeCode'
import PickupLocationPicker from '@/components/PickupLocationPicker'
import EasyDatepicker from '@/components/common/EasyDatepicker'
import { JSEncrypt } from 'jsencrypt'
export default {
  components: {
    FormLabel,
    TestPicker,
    OptimusTestPicker,
    MultiButtonPicker,
    StudyEnvelopeCode,
    PickupLocationPicker,
    EasyDatepicker,
  },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      TUMOR_TYPES,
      ROW_STATUS,
      disabledDates: {
        from: new Date(),
      },
      cancerSubtypeAtDiagnosisOtherInputField: '',
      msg: '',
      previousNewTreatmentsOptions: [],
      currentDiagnosisConfigs: [],
      customAvailableBiomarkers: [],
      availableBiomarkers: [],
      showModal: false,
      showMerckModal: false,
      calendarLang: es,
      codesValidated: [],
      studyTestWithCustomEnvelopeAreValid: false,
      lockTreatmentAndDrugs: false,
      current_item: {
        typeOfStudy: null,
        umbrellaId: null,
        sampleTypeId: null,
        doctor_id: null,
        envelope_code: null,
        dont_have_envelope_code: false,
        pickup_location_id: undefined,
        pickup_time_range: null,
        primaryTumorId: null,
        has_pathology_report: '',
        studyTests: [],
        additional_emails: [],
        additional_information: '',
        patient: {
          first_name: '',
          last_name: '',
          identity_document: '',
          smoker: 'unknown',
          insurance_details: '',
          birth_date: '',
          gender: null,
          ethinicity: null,
          telephone: '',
          email: '',
          diagnosis: null,
          diagnosis_location: null,
          diagnosis_stage: null,
          treatment_stage: null,
          previous_testing: null,
          previous_therapies: null,
          administrative_area_level_1: null,
          administrative_area_level_2: '',
          current_stage: null,
          cancer_subtype_at_diagnosis: null,
          drugs_used_for_prior_treatment: [],
          drugs_used_for_actual_treatment: [],
          previous_treatments: null,
        },
        has_consent: false,
        rasFromMerck: false,
      },
      options: {
        tests: [],
        pickup_locations: [],
        doctors: [],
      },
      otherDrugsUsedForPiorTreatments: '',
      otherDrugsUsedForActualTreatments: '',
      genesTests: [],
      amgenEnvelopeSet: false,
      squamous_carcinoma_is_selected: false,
      insuranceDetailsSearchResult: [],
      searchInsuranceDetailsFieldText: '',
      downloadFile: ['/static/CI_BMK.pdf'],
      consentText: ['Consentimiento Único Informado de BMK'],
      resetUnfilledFieldsForPfizerAndino: false,
      unfilledFields: [
        'Nombres',
        'Apellidos',
        'Horario de retiro',
        'Punto de retiro',
        'Sexo',
        'Fecha de Nacimiento',
        'Documento Nacional De Identidad/Cédula De Identidad/RUT',
        'Provincia o Estado',
        'Localidad',
        'Teléfono',
        'Documentación',
        'Consentimientos',
        'Cobertura Médica',
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('countries', ['countries', 'sampleTypes']),
    ...mapGetters('pharmas', ['pharmasEnabled']),
    ...mapGetters('panels', [
      'umbrellas',
      'umbrellasEnabled',
      'genes',
      'genesEnabled',
    ]),
    ...mapState('countries', ['getDefaultState']),
    ...mapState('testsGrouper', ['informatedConsents']),

    ...mapState('labelMappings', ['studyLabels', 'patientLabels']),
    ...mapState('biomarkers', ['biomarkersSetting', 'biomarkers']),
    ...mapState('primaryTumors', ['primaryTumors']),
    ...mapState('studies', ['umbrellaSettings']),
    ...mapGetters('studies', ['umbrellaSettings']),
    ...mapState('dbFrontendConfigurations', ['frontEndConfigs']),

    informatedConsentsComputed() {
      switch (this.current_item.typeOfStudy) {
        case 'bmks': //eslint-disable-next-line no-case-declarations
          let informatedConsentsBMK = this.informatedConsents.filter(
            (CI) => CI.testsGrouper.biomarkersTestsConfig.length > 0
          )
          if (
            !!this.current_item.primaryTumorId &&
            !!this.current_item.sampleTypeId
          ) {
            return informatedConsentsBMK.filter((CI) =>
              CI.testsGrouper.biomarkersTestsConfig.some((config) =>
                this.current_item.studyTests.some(
                  (test) =>
                    config.biomarkerId == test.testId &&
                    config.pharmaId == test.pharmaId
                )
              )
            )
          }
          return []

        default:
          //eslint-disable-next-line no-case-declarations
          let informatedConsentsUMB = this.informatedConsents.filter(
            (CI) => CI.testsGrouper.umbrellasTestsConfig.length > 0
          )
          if (
            !!this.current_item.primaryTumorId &&
            !!this.current_item.sampleTypeId
          ) {
            return informatedConsentsUMB.filter((CI) =>
              CI.testsGrouper.umbrellasTestsConfig.some((config) =>
                this.current_item.studyTests.some(
                  (test) =>
                    config.umbrellaId == this.current_item.umbrellaId &&
                    config.pharmaId == test.pharmaId
                )
              )
            )
          }
          return []
      }
    },
    isPatientPharmaSelected() {
      let patientPharma = this.pharmasEnabled.find((ph) => ph.code == 'patient')
      return this.current_item.studyTests.some(
        (st) => st.pharmaId == patientPharma.id
      )
    },
    PSPIsDisabledForThisUser() {
      return (
        this.$auth
          .user()
          .notifications.filter(
            (notif) =>
              notif.notificationType === 'PSP' && notif.readed === false
          ).length > 5
      )
    },
    administrativeAreasLevel1() {
      return this.frontEndConfigs.find(
        (config) =>
          config.countryId == this.currentCountry.id &&
          config.frontendFieldId == 'administrativeAreasLevel1'
      )
    },
    PSPIsDisabledAdvice() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.countryId == this.currentCountry.id &&
          config.frontendFieldId == 'PSPIsDisabledAdvice'
      )
    },
    isLungArgentina() {
      return (
        this.current_item.typeOfStudy === 'bmks' &&
        this.current_item.patient.diagnosis !== null &&
        this.currentlySelectedTumor.code === TUMOR_TYPES.LUNG &&
        this.currentCountryName === 'Argentina'
      )
    },

    autoGenerationOFAAdvice() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.countryId == this.currentCountry.id &&
          config.frontendFieldId == 'autoGenerationOFAAdvice'
      )
    },
    MMRColonAdvice() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.countryId == this.currentCountry.id &&
          config.frontendFieldId == 'MMRColonAdvice'
      )
    },
    actualTreatmentLineEnabled() {
      const lung = this.primaryTumors.find((t) => t.code == TUMOR_TYPES.LUNG)
      return (
        (this.current_item.typeOfStudy == 'bmks' &&
          this.current_item.primaryTumorId == lung.id &&
          this.currentCountryName === 'Argentina' &&
          this.current_item.patient.drugs_used_for_actual_treatment.length >
            0) ||
        this.current_item.patient.treatment_stage != null ||
        (this.current_item.typeOfStudy == 'bmks' &&
          this.current_item.primaryTumorId != lung.id) ||
        this.current_item.typeOfStudy == 'NGS' ||
        (this.current_item.typeOfStudy == 'PSP' &&
          this.current_item.patient.drugs_used_for_actual_treatment.length > 0)
      )
    },
    previousTreatmentAndDrugsEnabled() {
      return this.current_item.patient.treatment_stage != null
    },
    additionalFieldsEnabled() {
      const lung = this.primaryTumors.find((t) => t.code == TUMOR_TYPES.LUNG)
      return (
        this.current_item.typeOfStudy == 'PSP' ||
        (this.current_item.typeOfStudy == 'bmks' &&
          this.current_item.primaryTumorId == lung.id &&
          this.currentCountryName === 'Argentina')
      )
    },
    insuranceDetailsConfigurated() {
      return this.$store.state.dbFrontendConfigurations.frontEndConfigs.find(
        (config) =>
          config.countryId == this.currentCountry.id &&
          config.frontendFieldId == 'insurance_details'
      )
    },
    insuranceDetailsOptions() {
      let arrayedJSON =
        this.insuranceDetailsConfigurated.content &&
        typeof this.insuranceDetailsConfigurated.content === 'object'
          ? Object.keys(this.insuranceDetailsConfigurated.content).map(
              (value) => ({
                value: value,
                text: this.insuranceDetailsConfigurated.content[value],
              })
            )
          : []
      return arrayedJSON
    },
    primaryTumorOptions() {
      const tumorIdsWithBiomarkers = new Set()
      this.biomarkersSetting.forEach((biomarker) =>
        biomarker.allowedPharmas.forEach((pharma) =>
          pharma.customSettings.forEach((setting) =>
            tumorIdsWithBiomarkers.add(setting.primaryTumorId)
          )
        )
      )

      return this.primaryTumors
        .filter(
          (tumor) =>
            tumor.rowStatus === ROW_STATUS.ENABLED &&
            tumor.biomarkers.length > 0 &&
            tumorIdsWithBiomarkers.has(tumor.id)
        )
        .map((tumor) => ({ value: tumor.id, text: tumor.name }))
    },
    primaryTumorOptionsForUmbrella() {
      const tumorIdsWithUmbrellas = new Set()
      this.$store.state.studies.umbrellaSettings
        .map((umbrella) => {
          umbrella = JSON.parse(JSON.stringify(umbrella))
          let tmp_tumors_ids = new Set()
          umbrella.allowedPharmas = umbrella.allowedPharmas.filter(
            (pharma) => !pharma.code.startsWith('FORTREA')
          )
          umbrella.allowedPharmas.forEach((ph) =>
            ph.umbrellaCustomSettings.forEach((setting) =>
              tmp_tumors_ids.add(setting.primaryTumorId)
            )
          )
          umbrella.allowedPrimaryTumors = tmp_tumors_ids
          return umbrella
        })
        .filter((umbrella) => umbrella.allowedPharmas.length > 0)
        .forEach((umbrella) =>
          umbrella.allowedPrimaryTumors.forEach((pt) =>
            tumorIdsWithUmbrellas.add(parseInt(pt))
          )
        )
      return this.primaryTumors
        .filter(
          (tumor) =>
            tumor.rowStatus === ROW_STATUS.ENABLED &&
            tumor.biomarkers.length > 0 &&
            tumorIdsWithUmbrellas.has(tumor.id)
        )
        .map((tumor) => ({ value: tumor.id, text: tumor.name }))
    },
    primaryTumorOptionsForPreScreening() {
      const tumorIdsWithUmbrellas = new Set()
      this.$store.state.studies.umbrellaSettings
        .map((umbrella) => {
          umbrella = JSON.parse(JSON.stringify(umbrella))
          let tmp_tumors_ids = new Set()
          umbrella.allowedPharmas = umbrella.allowedPharmas.filter((pharma) =>
            pharma.code.startsWith('FORTREA')
          )
          umbrella.allowedPharmas.forEach((ph) =>
            ph.umbrellaCustomSettings.forEach((setting) =>
              tmp_tumors_ids.add(setting.primaryTumorId)
            )
          )
          umbrella.allowedPrimaryTumors = tmp_tumors_ids
          return umbrella
        })
        .filter(
          (umbrella) =>
            umbrella.allowedPharmas.length > 0 && umbrella.rowStatus === 'E'
        )
        .forEach((umbrella) =>
          umbrella.allowedPrimaryTumors.forEach((pt) =>
            tumorIdsWithUmbrellas.add(parseInt(pt))
          )
        )
      return this.primaryTumors
        .filter(
          (tumor) =>
            tumor.rowStatus === ROW_STATUS.ENABLED &&
            tumorIdsWithUmbrellas.has(tumor.id)
        )
        .map((tumor) => ({ value: tumor.id, text: tumor.name }))
    },
    genderOptions() {
      return getOptionsFor(this.patientLabels.gender)
    },
    diagnosisLocationOptions() {
      return getOptionsFor(this.patientLabels.diagnosisLocation)
    },
    smokerStatusOptions() {
      return getOptionsFor(this.patientLabels.smokerStatus)
    }, //eslint-disable-next-line vue/return-in-computed-property
    diagnosisOptions() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.setdiagnosisOptions()
    },
    umbrellaTestForThisPrimaryTumor() {
      let tmp = this.umbrellaSettings
        .filter((s) => s.rowStatus === ROW_STATUS.ENABLED)
        .map((umbrella) => {
          umbrella = JSON.parse(JSON.stringify(umbrella))
          umbrella.allowedPharmas = umbrella.allowedPharmas.filter(
            (pharma) =>
              !pharma.code.startsWith('FORTREA') &&
              pharma.umbrellaCustomSettings.some(
                (setting) =>
                  setting.sampleTypeId === this.current_item.sampleTypeId &&
                  setting.primaryTumorId === this.currentlySelectedTumor.id
              )
          )
          return umbrella
        })
        .filter((umbrella) => umbrella.allowedPharmas.length > 0)
      return tmp
    },
    preScreeningTestForThisPrimaryTumor() {
      let tmp = this.umbrellaSettings
        .filter((s) => s.rowStatus === ROW_STATUS.ENABLED)
        .map((umbrella) => {
          umbrella = JSON.parse(JSON.stringify(umbrella))
          umbrella.allowedPharmas = umbrella.allowedPharmas.filter(
            (pharma) =>
              pharma.code.startsWith('FORTREA') &&
              pharma.umbrellaCustomSettings.some(
                (setting) =>
                  setting.sampleTypeId === this.current_item.sampleTypeId &&
                  setting.primaryTumorId === this.currentlySelectedTumor.id
              )
          )
          return umbrella
        })
        .filter((umbrella) => umbrella.allowedPharmas.length > 0)
      return tmp
    },
    preScreeningPatientValuesForThisPrimaryTumor() {
      let primaryTumor = this.primaryTumors.find(
        (pt) => pt.id === this.current_item.primaryTumorId
      )
      if (primaryTumor)
        return new Object(
          this.$store.state.labelMappings.preScreeningLabels[
            `${primaryTumor.code}`
          ]
        )
      return {}
    },
    preScreeningCapsForThisUser() {
      let capsForThisUser = this.preScreeningTestForThisPrimaryTumor.filter(
        (tests) =>
          tests.caps.some(
            (c) =>
              c.userId == this.$auth.user().id && c.numberOfAvailableTests == 0
          )
      )
      let availableTestsForThisUser =
        this.preScreeningTestForThisPrimaryTumor.filter((tests) =>
          tests.caps.some(
            (c) =>
              c.userId == this.$auth.user().id && c.numberOfAvailableTests > 0
          )
        )
      let otherWithoutCaps = this.preScreeningTestForThisPrimaryTumor.filter(
        (tests) =>
          tests.caps.length == 0 ||
          tests.caps.some((c) => c.userId != this.$auth.user().id)
      )

      let allCaps = capsForThisUser.concat(otherWithoutCaps)
      let newAllCaps = allCaps.filter(
        (caps) => !availableTestsForThisUser.includes(caps)
      )
      return newAllCaps
    },
    biomarkersTestsCaps() {
      let caps = this.biomarkersSetting.filter(
        (setting) => setting.caps.length > 0
      )
      if (caps.length)
        caps.forEach((c, idx) => {
          c.caps.forEach((cap) => {
            let index = c.caps.indexOf(cap)
            let tmpSetting = this.currentCountry.settings.find(
              (setting) => setting.id == cap.testPerPharmaPerCountryId
            )
            let pharmaConfigCap = tmpSetting
              ? c.allowedPharmas.find((ph) => ph.id == tmpSetting.pharmaId)
              : null
            let indexOfPharmaConfigCap = pharmaConfigCap
              ? c.allowedPharmas.indexOf(pharmaConfigCap)
              : null
            if (pharmaConfigCap) {
              //eslint-disable-next-line no-prototype-builtins
              if (!pharmaConfigCap.hasOwnProperty('caps'))
                pharmaConfigCap.caps = []
              if (
                (c.caps[index].userId != null &&
                  c.caps[index].userId == this.$auth.user().id) ||
                c.caps[index].userId === null
              )
                pharmaConfigCap.caps.push(c.caps[index])
            }

            caps[idx].allowedPharmas[indexOfPharmaConfigCap] = pharmaConfigCap
          })
        })

      return caps
    },
    diagnosisStageOptions() {
      if (this.additionalFieldsEnabled)
        return getOptionsFor(
          this.preScreeningPatientValuesForThisPrimaryTumor[
            'stage_at_diagnosis'
          ]
        )
      return getOptionsFor(this.patientLabels.diagnosisStage)
    },
    treatmentStageOptions() {
      return getOptionsFor(this.patientLabels.treatmentStage)
    },
    previousTestingOptions() {
      return getOptionsFor(this.patientLabels.previousTesting)
    },
    pickupTimeRangeOptions() {
      return getOptionsFor(this.studyLabels.pickupTimeRange)
    },
    previousTherapiesOptions() {
      return getOptionsFor(this.patientLabels.previousTherapies)
    },
    previousTreatmentsOptions: {
      get() {
        return getOptionsFor(
          this.preScreeningPatientValuesForThisPrimaryTumor[
            'previous_treatments'
          ]
        )
      },
      set(val) {
        this.previousNewTreatmentsOptions = val
      },
    },
    ethinicityOptions() {
      return getOptionsFor(this.patientLabels.ethinicity)
    },
    cancerSubtypeAtDiagnosisOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor[
          'cancer_subtype_at_diagnosis'
        ]
      )
    },
    currentStageOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor['current_stage']
      )
    },
    drugsUsedForPriorTreatmentOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor[
          'drugs_used_for_prior_treatment'
        ]
      )
    },
    drugsUsedForActualTreatmentOptions() {
      return getOptionsFor(
        this.preScreeningPatientValuesForThisPrimaryTumor[
          'drugs_used_for_actual_treatment'
        ]
      )
    },

    hasBiomakersPharma() {
      const biomakers = this.pharmasEnabled.find(
        (pharma) => pharma.code.toLowerCase() === 'bio'
      )
      if (!biomakers) return false
      return !!this.current_item.studyTests.filter(
        (test) => test.pharmaId === biomakers.id
      ).length
    },
    hasMMRWithBristolTest() {
      const bristol = this.pharmasEnabled.find(
        (pharma) => pharma.code.toLowerCase() === 'bristol'
      )
      if (!bristol) return false
      return !!this.current_item.studyTests.filter(
        (test) =>
          test.pharmaId === bristol.id && test.testCode.toLowerCase() === 'mmr'
      ).length
    },
    pfizerAndinoPharma() {
      return this.pharmasEnabled.find((ph) =>
        ph.code.toUpperCase().startsWith('PFIZER ANDINO')
      )
    },
    showCheckboxForBioEnvelope() {
      const testWithMSD = this.current_item.studyTests
        .filter((studyTest) => !!studyTest.pharmaId)
        .map((studyTest) =>
          this.pharmasEnabled
            .find((pharma) => pharma.id === studyTest.pharmaId)
            .code.toLowerCase()
        )
        .includes('msd')

      const customEnvelopeWithMSD = this.pharmasWithCustomEnvelope.some(
        (pharma) => pharma.code.toLowerCase() === 'msd'
      )

      if (customEnvelopeWithMSD) return true
      else if (testWithMSD) return false
      else return true
    },
    envelopesAreValid() {
      //FLAG
      if (this.current_item.typeOfStudy !== 'bmks') {
        return !!(
          this.codesValidated.every((code) => code.valid) &&
          (!this.requiredBiomakerEnvelope ||
            this.current_item.dont_have_envelope_code == true ||
            this.current_item.envelope_code != null)
        )
      }
      return (
        this.codesValidated.every((code) => code.valid) &&
        (!this.requiredBiomakerEnvelope ||
          this.current_item.dont_have_envelope_code ||
          !!this.current_item.envelope_code)
      )
    },
    //eslint-disable-next-line vue/return-in-computed-property
    displayFullForm() {
      if (this.current_item.typeOfStudy === 'bmks') {
        return (
          this.current_item.doctor_id &&
          //          this.studyTestWithCustomEnvelopeAreValid &&
          this.envelopesAreValid &&
          this.current_item.studyTests.length &&
          this.current_item.studyTests.length > 0 &&
          this.current_item.studyTests.every(
            ({ pharmaId }) => pharmaId !== null
          )
        )
      }
      if (
        this.current_item.typeOfStudy === 'NGS' ||
        this.current_item.typeOfStudy === 'PSP'
      ) {
        return (
          this.current_item.doctor_id &&
          this.current_item.umbrellaId &&
          this.envelopesAreValid &&
          this.current_item.studyTests.length &&
          this.current_item.studyTests.length > 0 &&
          this.current_item.studyTests.every(
            ({ pharmaId }) => pharmaId !== null
          )
        )
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    requiredBiomakerEnvelope() {
      const pharmaIds = this.pharmasWithCustomEnvelope.map(
        (pharma) => pharma.id
      )
      return (
        this.current_item.studyTests.filter(
          (study) => !pharmaIds.includes(study.pharmaId)
        ).length !== 0
      )
    },
    // eslint-disable-next-line vue/return-in-computed-property
    requiredUmbrellaEnvelope() {
      if (this.pharmasWithCustomEnvelopeForNGS != undefined) {
        const pharmaIds = this.pharmasWithCustomEnvelopeForNGS.map(
          (pharma) => pharma.id
        )
        return (
          this.current_item.studyTests.filter((tests) =>
            pharmaIds.includes(tests.pharmaId)
          ).length > 0
        )
      }
    },
    pharmasWithCustomEnvelope() {
      let options = this.availableBiomarkers
        .flatMap((biomarker) =>
          biomarker.allowedPharmas.map((pharma) => ({
            biomarkerCode: biomarker.code,
            biomarkerId: biomarker.id,
            pharmaId: pharma.id,
            pharmaName: pharma.name,
            pharmaCode: pharma.code,
            requiresPharmaEnvelopeCode: pharma.requiresPharmaEnvelopeCode,
            validateEnvelopeWithServer: pharma.validateEnvelopeWithServer,
            requiresValidationWithNewApi: pharma.requiresValidationWithNewApi,
            validateEnvelopeWithNewApi: pharma.validateEnvelopeWithNewApi,
            admitsEnvelopeAbscense: pharma.admitsEnvelopeAbscense,
            requiresQrOption: pharma.requiresQrOption,
          }))
        )
        .filter(
          (opt) =>
            this.current_item.studyTests.some(
              (currentItem) =>
                currentItem.testCode === opt.biomarkerCode &&
                currentItem.pharmaId === opt.pharmaId
            ) && opt.requiresPharmaEnvelopeCode
        )
        .map((opt) => ({
          id: opt.pharmaId,
          code: opt.pharmaCode,
          name: opt.pharmaName,
          biomarkerId: opt.biomarkerId,
          validateEnvelopeWithServer: opt.validateEnvelopeWithServer,
          validateEnvelopeWithNewApi: opt.validateEnvelopeWithNewApi,
          requiresValidationWithNewApi: opt.requiresValidationWithNewApi,
          admitsEnvelopeAbscense: opt.admitsEnvelopeAbscense,
          requiresQrOption: opt.requiresQrOption,
        }))
      let pharmaOptions = []
      for (let index = 0; index < options.length; index++) {
        const element = options[index]
        var subArray = options.filter((opt) => opt.code === element.code)
        if (!pharmaOptions.some((option) => option.code == element.code)) {
          if (subArray.length === 1) {
            pharmaOptions.push(element)
          } else if (
            subArray.some(
              (option) => option.requiresValidationWithNewApi === true
            )
          ) {
            //eslint-disable-next-line no-undef
            let newElement = subArray.filter(
              (opt) => opt.requiresValidationWithNewApi === true
            )
            newElement.forEach((el) => {
              pharmaOptions.push(el)
            })
          } else if (
            subArray.every((item) => item.requiresValidationWithNewApi !== true)
          ) {
            pharmaOptions = Object.values(
              subArray.reduce((acc, curr) => {
                const {
                  code,
                  requiresValidationWithNewApi,
                  requiresPharmaEnvelopeCode,
                  ...rest
                } = curr
                const key = `${code}`

                if (
                  !requiresValidationWithNewApi ||
                  requiresPharmaEnvelopeCode
                ) {
                  if (!acc[key]) {
                    acc[key] = {
                      ...rest,
                      code,
                      biomarkerIds: [curr.biomarkerId],
                      requiresValidationWithNewApi:
                        requiresValidationWithNewApi,
                    }
                  } else {
                    acc[key].biomarkerIds.push(curr.biomarkerId)
                  }
                }
                return acc
              }, {})
            )
          } else if (
            !pharmaOptions.some((option) => option.code == element.code)
          ) {
            Object.groupBy(
              subArray,
              ({ requiresValidationWithNewApi, requiresPharmaEnvelopeCode }) =>
                requiresPharmaEnvelopeCode || requiresValidationWithNewApi
            )['true'].forEach((val) => {
              pharmaOptions.push(val)
            })
          }
        }
      }
      return pharmaOptions
    },
    // eslint-disable-next-line vue/return-in-computed-property
    pharmasWithCustomEnvelopeForNGS() {
      if (this.umbrellaTestForThisPrimaryTumor.length > 0) {
        const options = this.umbrellaTestForThisPrimaryTumor
          .flatMap((umbrella) =>
            umbrella.allowedPharmas.map((pharma) => ({
              biomarkerCode: umbrella.code,
              biomarkerId: umbrella.id,
              pharmaId: pharma.id,
              pharmaName: pharma.name,
              pharmaCode: pharma.code,
              requiresPharmaEnvelopeCode: pharma.requiresPharmaEnvelopeCode,
              validateEnvelopeWithServer: pharma.validateEnvelopeWithServer,
              validateEnvelopeWithNewApi: pharma.validateEnvelopeWithNewApi,
              admitsEnvelopeAbscense: pharma.admitsEnvelopeAbscense,
              requiresQrOption: pharma.requiresQrOption,
            }))
          )
          .filter(
            (opt) =>
              this.current_item.studyTests.some(
                (currentItem) => currentItem.pharmaId === opt.pharmaId
              ) &&
              opt.requiresPharmaEnvelopeCode &&
              this.current_item.umbrellaId === opt.biomarkerId
          )
          .map((opt) => ({
            id: opt.pharmaId,
            code: opt.pharmaCode,
            name: opt.pharmaName,
            validateEnvelopeWithServer: opt.validateEnvelopeWithServer,
            validateEnvelopeWithNewApi: opt.validateEnvelopeWithNewApi,
            requiresPharmaEnvelopeCode: opt.requiresPharmaEnvelopeCode,
            admitsEnvelopeAbscense: opt.admitsEnvelopeAbscense,
            requiresQrOption: opt.requiresQrOption,
          }))
        return (
          Object.groupBy(
            options,
            ({ validateEnvelopeWithNewApi, requiresPharmaEnvelopeCode }) =>
              requiresPharmaEnvelopeCode || validateEnvelopeWithNewApi
          )['true'] || []
        )
      }
      return []
    },
    //eslint-disable-next-line vue/return-in-computed-property
    requiredItemsForEnvelope() {
      const ret = [
        { text: 'Informe de Anatomía Patológica', icon: 'assignment' },
      ]

      if (!this.current_item.studyTests || !this.current_item.studyTests.length)
        return ret
      if (this.current_item.typeOfStudy === 'bmks') {
        this.current_item.studyTests.forEach((test) => {
          const pharma = this.pharmasEnabled.find(
            (pharma) => pharma.id === test['pharmaId']
          )

          const biomaker = this.availableBiomarkers.find(
            (biomaker) => test.testCode === biomaker.code
          )
          const testName = biomaker ? biomaker.name : null
          let docs
          const setting = testName
            ? this.availableBiomarkers
                .find((biomarker) => biomarker.id === test.testId)
                .allowedPharmas.find((pharma) => pharma.id === test.pharmaId)
                .customSettings.find(
                  (setting) =>
                    this.currentlySelectedTumor.id === setting.primaryTumorId &&
                    [this.current_item.sampleTypeId, null].includes(
                      setting.sampleTypeId
                    )
                )
            : null

          if (pharma.requirements || (setting && setting.documentation))
            docs = { text: `${testName} (${pharma.name}):`, icon: 'email' }
          if (pharma.requirements)
            docs.text = `${docs.text} ${pharma.requirements}.`
          if (setting && setting.documentation)
            docs.text = `${docs.text} ${setting.documentation}.`
          if (docs) ret.push(docs)
        })
        return ret
      }
      if (
        this.current_item.typeOfStudy === 'NGS' ||
        this.current_item.typeOfStudy === 'PSP'
      ) {
        var umbrellaTest = this.current_item.studyTests.find(
          (test) => test.umbrellaId
        )
        this.current_item.studyTests.forEach((test) => {
          const pharma = this.pharmasEnabled.find(
            (pharma) => pharma.id === test['pharmaId']
          )
          const actualTest = this.umbrellasEnabled.find(
            (u) => test.umbrellaId === u.id
          )
          let testName
          if (actualTest) {
            testName = actualTest.name
          } else {
            testName = this.$store.state.studies.umbrellaSettings
              .find((umbrella) => umbrella.id == umbrellaTest.umbrellaId)
              .biomarkersList.find((bmk) => bmk.id == test.testId).name
          }
          let docs
          let setting = test.umbrellaId
            ? this.umbrellaSettings
                .find((umbrella) => umbrella.id === test.umbrellaId)
                .allowedPharmas.find((pharma) => pharma.id === test.pharmaId)
                .umbrellaCustomSettings.find(
                  (setting) =>
                    this.currentlySelectedTumor.id === setting.primaryTumorId &&
                    [this.current_item.sampleTypeId, null].includes(
                      setting.sampleTypeId
                    )
                )
            : this.umbrellaSettings
                .find((umbrella) => umbrella.id === umbrellaTest.umbrellaId)
                .biomarkersList.find((bmk) => bmk.code === test.testCode)
                .pharmas.find((pharma) => pharma.id === test.pharmaId)
          if (!setting) return
          if (pharma.requirements || (setting && setting.documentation))
            docs = { text: `${testName} (${pharma.name}):`, icon: 'email' }
          if (pharma.requirements)
            docs.text = `${docs.text} ${pharma.requirements}.`
          if (setting && setting.documentation)
            docs.text = `${docs.text} ${setting.documentation}.`
          if (docs) ret.push(docs)
        })
        const uniqueArr = ret.reduce((acc, current) => {
          const isDuplicate = acc.find((item) => {
            return item.text === current.text && item.icon === current.icon
          })
          if (!isDuplicate) {
            acc.push(current)
          }
          return acc
        }, [])
        return uniqueArr
      }
    },
    availableSampleTypes() {
      const sampleTypeIds = new Set()
      this.biomarkersSetting.forEach((biomarker) =>
        biomarker.allowedPharmas.forEach((pharma) =>
          pharma.customSettings
            .filter(
              (setting) =>
                setting.primaryTumorId === this.currentlySelectedTumor.id
            )
            .forEach((setting) => sampleTypeIds.add(setting.sampleTypeId))
        )
      )
      const options = this.sampleTypes
        .map((sampleType) => ({ value: sampleType.id, text: sampleType.label }))
        .filter((sampleType) => sampleTypeIds.has(sampleType.value))

      if (options.length === 1)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.current_item.sampleTypeId = options[0].value

      return options
    },
    availableSampleTypesForUmbrellas() {
      const sampleTypeIds = new Set()
      this.$store.state.studies.umbrellaSettings.map((umbrella) => {
        umbrella = JSON.parse(JSON.stringify(umbrella))
        umbrella.allowedPharmas = umbrella.allowedPharmas.filter(
          (pharma) => !pharma.code.startsWith('FORTREA')
        )

        umbrella.allowedPharmas.forEach((pharma) =>
          pharma.umbrellaCustomSettings
            .filter(
              (setting) =>
                setting.primaryTumorId === this.currentlySelectedTumor.id
            )
            .forEach((setting) => sampleTypeIds.add(setting.sampleTypeId))
        )
        return umbrella
      })
      const options = this.sampleTypes
        .map((sampleType) => ({ value: sampleType.id, text: sampleType.label }))
        .filter((sampleType) => sampleTypeIds.has(sampleType.value))

      if (options.length === 1)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.current_item.sampleTypeId = options[0].value

      return options
    },
    availableSampleTypesForPreScreening() {
      const sampleTypeIds = new Set()
      this.$store.state.studies.umbrellaSettings.map((umbrella) => {
        umbrella = JSON.parse(JSON.stringify(umbrella))
        umbrella.allowedPharmas = umbrella.allowedPharmas.filter((pharma) =>
          pharma.code.startsWith('FORTREA')
        )

        umbrella.allowedPharmas.forEach((pharma) =>
          pharma.umbrellaCustomSettings
            .filter(
              (setting) =>
                setting.primaryTumorId === this.currentlySelectedTumor.id
            )
            .forEach((setting) => sampleTypeIds.add(setting.sampleTypeId))
        )
        return umbrella
      })
      const options = this.sampleTypes
        .map((sampleType) => ({ value: sampleType.id, text: sampleType.label }))
        .filter((sampleType) => sampleTypeIds.has(sampleType.value))

      if (options.length === 1)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.current_item.sampleTypeId = options[0].value

      return options
    },
    sampleIsLiquid() {
      const sampleType = this.sampleTypes.find(
        (sampleType) => sampleType.id === this.current_item.sampleTypeId
      )

      if (sampleType) return sampleType.code === 'liquid_sample'
      else return false
    },
    can_send_form() {
      if (
        this.checkRASFromMerck == true &&
        this.currentCountryName !== 'Argentina'
      ) {
        return (
          this.current_item.has_pathology_report &&
          (!this.requiresConsentDeclaration() ||
            this.current_item.has_consent) &&
          this.isValid() &&
          this.checkValidOtherField() === 'valid' &&
          this.current_item.rasFromMerck
        )
      } else {
        return (
          this.current_item.has_pathology_report &&
          (!this.requiresConsentDeclaration() ||
            this.current_item.has_consent) &&
          this.isValid() &&
          this.checkValidOtherField() === 'valid'
        )
      }
    },
    email_notifications_to() {
      return this.$auth.user().email
    },

    currentCountry() {
      let country = this.countries.find(
        (c) => c.id == this.$auth.user().country.id
      )
      return country
    },
    currentCountryName() {
      if (this.currentCountry) return this.currentCountry.name
      return null
    },
    currentlySelectedTumor() {
      return this.primaryTumors.find(
        (tumor) => tumor.id === this.current_item.primaryTumorId
      )
    },
    umbrellaTestSettings() {
      return this.$store.state.studies.umbrellaSettings
    },
    checkRASFromAmgen() {
      const selectedPharmas = this.current_item.studyTests.map(
        (test) => test.pharmaId
      )
      const amgen = this.pharmasEnabled.find(
        (pharma) => pharma.code === 'AMGEN'
      )
      let result = false
      function isDistinctFromUndefined(val) {
        if (val != undefined) {
          return true
        }
      }
      const isBrafAmgen = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'braf'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'braf'
          )[0].pharmaId == amgen.id
        : null
      const isKrasAmgen = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'kras'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'kras'
          )[0].pharmaId == amgen.id
        : null
      const isNrasAmgen = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'nras'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'nras'
          )[0].pharmaId == amgen.id
        : null
      if (amgen != undefined) {
        if (
          selectedPharmas.includes(amgen.id) &&
          (isKrasAmgen == true || isNrasAmgen == true || isBrafAmgen == true)
        ) {
          result = true
        }
      } else {
        result = false
      }
      return result
    },
    checkRASFromMerck() {
      const selectedPharmas = this.current_item.studyTests.map(
        (test) => test.pharmaId
      )
      const merck = this.pharmasEnabled.find((pharma) => pharma.code === 'MSD')
      let result = false
      function isDistinctFromUndefined(val) {
        if (val != undefined) {
          return true
        }
      }
      const isBrafMerck = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'braf'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'braf'
          )[0].pharmaId == merck.id
        : null
      const isKrasMerck = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'kras'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'kras'
          )[0].pharmaId == merck.id
        : null
      const isNrasMerck = isDistinctFromUndefined(
        this.current_item.studyTests.filter(
          (test) => test.testCode === 'nras'
        )[0]
      )
        ? this.current_item.studyTests.filter(
            (test) => test.testCode === 'nras'
          )[0].pharmaId == merck.id
        : null
      if (merck != undefined) {
        if (
          selectedPharmas.includes(merck.id) &&
          (isKrasMerck == true || isNrasMerck == true || isBrafMerck == true)
        ) {
          result = true
        }
      } else {
        result = false
      }
      return result
    },
  },
  watch: {
    'current_item.patient.identity_document'(val) {
      if (val) {
        this.current_item.patient.identity_document = val.replace(/[^0-9]/g, '')
      }
    },
    'current_item.typeOfStudy'(val) {
      if (val === 'PSP') {
        //downloadFile: ['/static/CI_BMK.pdf'],
        // consentText: ['Consentimiento Único Informado de BMK']
        if (this.downloadFile.find((file) => file === '/static/CI_BMK.pdf')) {
          this.downloadFile.splice(
            this.downloadFile.indexOf('/static/CI_BMK.pdf'),
            1
          )
        }
        if (
          this.consentText.find(
            (text) => text === 'Consentimiento Único Informado de BMK'
          )
        ) {
          this.consentText.splice(
            this.consentText.indexOf('Consentimiento Único Informado de BMK'),
            1
          )
        }

        if (
          !this.unfilledFields.find(
            (f) => f === 'Subtipo del cáncer al diagnóstico'
          )
        ) {
          this.unfilledFields.push('Subtipo del cáncer al diagnóstico')
        }
        if (
          !this.unfilledFields.find((f) => f === 'Tratamientos previos') &&
          this.current_item.patient.drugs_used_for_actual_treatment.length > 0
        ) {
          this.unfilledFields.push('Tratamientos previos')
        }
        if (!this.unfilledFields.find((f) => f === 'Etnia')) {
          this.unfilledFields.push('Etnia')
        }
        if (!this.unfilledFields.find((f) => f === 'Estadio de enfermedad')) {
          this.unfilledFields.push('Estadio de enfermedad')
        }
        if (!this.unfilledFields.find((f) => f === 'Estadio actual')) {
          this.unfilledFields.push('Estadio actual')
        }
      } else if (val !== 'PSP') {
        if (
          this.unfilledFields.find(
            (f) => f === 'Subtipo del cáncer al diagnóstico'
          )
        ) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Subtipo del cáncer al diagnóstico'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Tratamientos previos')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Tratamientos previos'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Estadio de enfermedad')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Estadio de enfermedad'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Estadio actual')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Estadio actual'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Etnia')) {
          this.unfilledFields.splice(this.unfilledFields.indexOf('Etnia'), 1)
        }
      }
    },
    'current_item.primaryTumorId'(value) {
      const lung = this.primaryTumors.find(
        (tumor) => tumor.code === TUMOR_TYPES.LUNG
      )
      if (
        value === lung.id &&
        this.current_item.typeOfStudy === 'bmks' &&
        this.currentCountryName === 'Argentina'
      ) {
        this.loadUmbrellaInfo()
        if (!this.downloadFile.includes('/static/CI_OFA.pdf')) {
          this.downloadFile.push('/static/CI_OFA.pdf')
          this.consentText.push('Consentimiento Informado General')
        }
        if (!this.downloadFile.includes('/static/CI_BIOBANCO.pdf')) {
          this.downloadFile.push('/static/CI_BIOBANCO.pdf')
          this.consentText.push(
            'Consentimiento Informado Adicional cesion de muestras al BIOBANCO'
          )
        }

        if (
          this.consentText.includes('Consentimiento Único Informado de BMK')
        ) {
          this.consentText.splice(
            this.consentText.indexOf('Consentimiento Único Informado de BMK'),
            1
          )
        }
        if (this.downloadFile.includes('/static/CI_BMK.pdf')) {
          this.downloadFile.splice(
            this.downloadFile.indexOf('/static/CI_BMK.pdf'),
            1
          )
        }

        if (
          !this.unfilledFields.find(
            (f) => f === 'Subtipo del cáncer al diagnóstico'
          )
        ) {
          this.unfilledFields.push('Subtipo del cáncer al diagnóstico')
        }
        if (
          !this.unfilledFields.find((f) => f === 'Tratamientos previos') &&
          this.current_item.patient.drugs_used_for_actual_treatment.length > 0
        ) {
          console.log('ACTIVO')
          this.unfilledFields.push('Tratamientos previos')
        }
        if (!this.unfilledFields.find((f) => f === 'Etnia')) {
          this.unfilledFields.push('Etnia')
        }
        if (!this.unfilledFields.find((f) => f === 'Estadio de enfermedad')) {
          this.unfilledFields.push('Estadio de enfermedad')
        }
        if (!this.unfilledFields.find((f) => f === 'Estadio actual')) {
          this.unfilledFields.push('Estadio actual')
        }
      }
      if (
        value !== lung.id &&
        this.current_item.typeOfStudy === 'bmks' &&
        this.currentCountryName === 'Argentina'
      ) {
        if (!this.downloadFile.includes('/static/CI_BMK.pdf')) {
          this.downloadFile.push('/static/CI_BMK.pdf')
          this.consentText.push('Consentimiento Único Informado de BMK')
        }

        if (this.downloadFile.includes('/static/CI_OFA.pdf')) {
          this.consentText.splice(
            this.consentText.indexOf('Consentimiento Informado General'),
            1
          )
          this.downloadFile.splice(
            this.downloadFile.indexOf('/static/CI_OFA.pdf'),
            1
          )
        }
        if (this.downloadFile.includes('/static/CI_BIOBANCO.pdf')) {
          this.downloadFile.splice(
            this.downloadFile.indexOf('/static/CI_BIOBANCO.pdf'),
            1
          )
          this.consentText.splice(
            this.consentText.indexOf(
              'Consentimiento Informado Adicional cesion de muestras al BIOBANCO'
            ),
            1
          )
        }

        if (
          this.unfilledFields.find(
            (f) => f === 'Subtipo del cáncer al diagnóstico'
          )
        ) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Subtipo del cáncer al diagnóstico'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Tratamientos previos')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Tratamientos previos'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Estadio de enfermedad')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Estadio de enfermedad'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Estadio actual')) {
          this.unfilledFields.splice(
            this.unfilledFields.indexOf('Estadio actual'),
            1
          )
        }
        if (this.unfilledFields.find((f) => f === 'Etnia')) {
          this.unfilledFields.splice(this.unfilledFields.indexOf('Etnia'), 1)
        }
      }
    },
    customAvailableBiomarkers(newTests) {
      if (newTests) this.customAvailableBiomarkers = newTests
    },
    'current_item.additional_information'() {},
    'current_item.patient.drugs_used_for_actual_treatment'() {
      if (
        !this.unfilledFields.find((f) => f === 'Línea de tratamiento actual')
      ) {
        console.log('ACTIVO linea')
        this.unfilledFields.push('Línea de tratamiento actual')
      }
    },
    'current_item.patient.treatment_stage'(val) {
      switch (val) {
        case 'stage_1':
          this.current_item.patient.drugs_used_for_prior_treatment = []
          this.current_item.patient.previous_treatments = null
          this.lockTreatmentAndDrugs = true
          if (this.unfilledFields.find((f) => f === 'Tratamientos previos')) {
            this.unfilledFields.splice(
              this.unfilledFields.indexOf('Tratamientos previos'),
              1
            )
          }
          break
        default:
          if (!this.unfilledFields.includes('Tratamientos previos')) {
            this.unfilledFields.push('Tratamientos previos')
          }
          this.lockTreatmentAndDrugs = false
          break
      }
    },
    'current_item.dont_have_envelope_code'() {
      if (this.current_item.dont_have_envelope_code)
        this.current_item.envelope_code = null
    },
    'this.current_item.rasFromMerck'() {
      if (this.checkRASFromAmgen == false || this.checkRASFromMerck == false) {
        this.current_item.rasFromMerck = false
      }
    },
    pharmasWithCustomEnvelope() {
      if (this.current_item.typeOfStudy !== 'bmks') return []
      //if (this.current_item.studyTests.every((st)=>st.)) this.codesValidated = []
      this.codesValidated = this.pharmasWithCustomEnvelope.map((pharma) => {
        const index = this.codesValidated.findIndex(
          (code) =>
            code.pharmaId === pharma.id &&
            code.biomarkerId === pharma.biomarkerId
        )
        if (index !== -1) {
          return {
            pharmaId: pharma.id,
            valid: false,
            biomarkerId: pharma.biomarkerId,
            biomarkerIds: pharma.biomarkerIds,
          }
        } else {
          return {
            pharmaId: pharma.id,
            valid: false,
            biomarkerId: pharma.biomarkerId,
            biomarkerIds: pharma.biomarkerIds,
          }
        }
      })
      const amgen = this.pharmasEnabled.find(
        (pharma) => pharma.code === 'AMGEN'
      )

      if (this.$route.query.envelope_code && amgen && !this.amgenEnvelopeSet) {
        this.setStudyTestCustomEnvelopeCode({
          value: this.$route.query.envelope_code,
          pharmaId: amgen.id,
          isValidCode: false,
        })
        this.amgenEnvelopeSet = true
      }
    },

    pharmasWithCustomEnvelopeForNGS() {
      if (this.current_item.typeOfStudy === 'bmks') return []
      this.codesValidated = this.pharmasWithCustomEnvelopeForNGS.map(
        (pharma) => {
          const index = this.codesValidated.findIndex(
            (code) => code.pharmaId === pharma.id
          )
          if (index !== -1) {
            return { ...this.codesValidated[index] }
          } else {
            return {
              pharmaId: pharma.id,
              valid: false,
            }
          }
        }
      )
    },
    unfilledFields(val) {
      if (
        this.current_item.studyTests.find(
          (st) => st.pharmaId === this.pfizerAndinoPharma.id
        )
      ) {
        if (this.checkStringInAField(val, 'Cobertura Médica')) {
          val.splice(val.indexOf('Cobertura Médica'), 1)
        }
        if (val.includes('Fecha de Nacimiento')) {
          val.splice(val.indexOf('Fecha de Nacimiento'), 1)
        }
        if (val.includes('Sexo')) {
          val.splice(val.indexOf('Sexo'), 1)
        }
        if (val.includes('Teléfono')) {
          val.splice(val.indexOf('Teléfono'), 1)
        }
      } else if (this.resetUnfilledFieldsForPfizerAndino == false) {
        if (!val.includes('Teléfono')) {
          val.push('Teléfono')
        }
        if (!val.includes('Sexo')) {
          val.push('Sexo')
        }
        if (!val.includes('Fecha de Nacimiento')) {
          val.push('Fecha de Nacimiento')
        }
        if (!this.checkStringInAField(val, 'Cobertura Médica')) {
          val.push('Cobertura Médica')
        }
        this.resetUnfilledFieldsForPfizerAndino = true
      }
    },
    'current_item.patient.drugs_used_for_prior_treatment'(val) {
      if (val.length > 0) {
        this.previousTreatmentsOptions = getOptionsFor(
          this.preScreeningPatientValuesForThisPrimaryTumor[
            'previous_treatments'
          ]
        ).filter((opt) => opt.value != 'untreated')
      } else {
        this.previousTreatmentsOptions = getOptionsFor(
          this.preScreeningPatientValuesForThisPrimaryTumor[
            'previous_treatments'
          ]
        )
      }
    },
  },
  async mounted() {
    const response = await this.axios.get('/api2/user/doctors/confirmed')
    this.options.doctors = response.data.map((d) => ({
      ...d,
      value: d['id'],
      text: `${d['lastName']}, ${d['firstName']}`,
    }))
    if (this.options.doctors.length === 1)
      this.current_item.doctor_id = this.options.doctors[0].value

    this.getSampleTypes()

    await this.getLabs()
    await this.getPharmas()
    await this.getBiomarkersSetting()
    await this.getPrimaryTumors()

    if (this.$route.query.envelope_code) {
      this.current_item.primaryTumorId = this.primaryTumors.find(
        (tumor) => tumor.code === TUMOR_TYPES.COLON
      ).id
    }
    this.fetchStudyLabels()
    this.fetchPatientLabels()
    await this.getCountries()
    this.getBiomarkers()
    this.getInformatedConsents()
    await this.getFrontEndConfigurationsForCreateStudy()
    if (
      !this.currentCountry.countryUmbrellaSetting.lentgh &&
      this.current_item.typeOfStudy == null
    ) {
      this.current_item.typeOfStudy = 'bmks'
    }
    if (this.currentCountryName === 'Argentina') {
      this.downloadFile = ['/static/CI_BMK.pdf']
    } else if (this.currentCountryName == 'México') {
      this.downloadFile = ['/static/CI_BMK_MX.pdf']
    }
  },
  validations() {
    const getBaseValidations = () => ({
      current_item: {
        patient: {
          first_name: { required },
          last_name: { required },
          telephone: {
            required: requiredIf(function () {
              return !this.current_item.studyTests.find(
                (st) => st.pharmaId === this.pfizerAndinoPharma.id
              )
            }),
          },
          insurance_details: {
            required: requiredIf(function () {
              return !this.current_item.studyTests.find(
                (st) => st.pharmaId === this.pfizerAndinoPharma.id
              )
            }),
          },
          identity_document: { required },
          birth_date: {
            required: requiredIf(function () {
              return !this.current_item.studyTests.find(
                (st) => st.pharmaId === this.pfizerAndinoPharma.id
              )
            }),
          },
          smoker: { required },
          gender: {
            required: requiredIf(function () {
              return !this.current_item.studyTests.find(
                (st) => st.pharmaId === this.pfizerAndinoPharma.id
              )
            }),
          },
          diagnosis: {
            required: requiredIf(function () {
              return (
                this.currentlySelectedTumor.code === TUMOR_TYPES.LUNG &&
                this.current_item.typeOfStudy !== 'PSP'
              )
            }),
          },
          diagnosis_stage: {
            required: requiredIf(function () {
              return this.additionalFieldsEnabled
            }),
          },
          cancer_subtype_at_diagnosis: {
            required: requiredIf(function () {
              return this.additionalFieldsEnabled
            }),
          },
          current_stage: {
            required: requiredIf(function () {
              return (
                this.additionalFieldsEnabled &&
                this.currentStageOptions.length > 0
              )
            }),
          },
          ethinicity: {
            required: requiredIf(function () {
              return this.additionalFieldsEnabled
            }),
          },
          previous_treatments: {
            required: requiredIf(function () {
              return (
                this.current_item.patient.drugs_used_for_actual_treatment
                  .length != 0 &&
                this.additionalFieldsEnabled &&
                !this.lockTreatmentAndDrugs
              )
            }),
          },
          treatment_stage: {
            required: requiredIf(function () {
              return (
                this.current_item.patient.drugs_used_for_actual_treatment
                  .length != 0 &&
                this.additionalFieldsEnabled &&
                !this.lockTreatmentAndDrugs
              )
            }),
          },
          administrative_area_level_1: { required },
          administrative_area_level_2: { required },
        },
        has_pathology_report: { required },
        doctor_id: { required },
        primaryTumorId: { required },
        pickup_time_range: { required },
      },
    })

    if (this.sampleIsLiquid) {
      return getBaseValidations()
    } else {
      let validator = getBaseValidations()
      validator.current_item['pickup_location_id'] = {
        required: requiredIf(function () {
          return !this.current_item.additional_information
        }),
        mustBeDefined: (value) => value !== undefined,
      }
      return validator
    }
  },
  methods: {
    ...mapActions('countries', ['getSampleTypes']),
    ...mapActions('labs', ['getLabs']),
    ...mapActions('countries', ['getCountries']),
    ...mapActions('labelMappings', [
      'fetchStudyLabels',
      'fetchPatientLabels',
      'getPreScreeningLabels',
    ]),
    ...mapActions('pharmas', ['getPharmas']),
    ...mapActions('biomarkers', ['getBiomarkersSetting', 'getBiomarkers']),
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('dbFrontendConfigurations', [
      'getFrontEndConfigurationsForCreateStudy',
    ]),
    ...mapActions('testsGrouper', ['getInformatedConsents']),
    searchInsuranceDetails(value) {
      if (!this.current_item.patient.insurance_details)
        setTimeout(() => {
          this.insuranceDetailsSearchResult =
            this.insuranceDetailsOptions.filter(
              (insurance) =>
                insurance.text.includes(value) ||
                insurance.text.toLowerCase().includes(value) ||
                insurance.text.toUpperCase().includes(value)
            )
        }, 5)
      return
    },
    addDrugsUsedForPriorTreatments() {
      let firstSplit = this.otherDrugsUsedForPiorTreatments.split(',')
      for (let index = 0; index < firstSplit.length; index++) {
        let drugWrited = firstSplit[index]
        if (drugWrited === '' || drugWrited === ' ') continue
        else if (drugWrited.startsWith(' ') || drugWrited.endsWith(' ')) {
          let drugProcessed = drugWrited.split(' ')[1]
          if (
            !this.current_item.patient.drugs_used_for_prior_treatment.includes(
              drugProcessed
            )
          ) {
            this.current_item.patient.drugs_used_for_prior_treatment.push(
              drugProcessed
            )
          }
        } else {
          if (
            !this.current_item.patient.drugs_used_for_prior_treatment.includes(
              drugWrited
            )
          ) {
            this.current_item.patient.drugs_used_for_prior_treatment.push(
              drugWrited
            )
          }
        }
      }
      if (
        this.current_item.patient.drugs_used_for_prior_treatment.includes(
          'other'
        )
      ) {
        this.current_item.patient.drugs_used_for_prior_treatment.splice(
          this.current_item.patient.drugs_used_for_prior_treatment.indexOf(
            'other'
          ),
          1
        )
      }
    },
    addDrugsUsedForActualTreatments() {
      let firstSplit = this.otherDrugsUsedForActualTreatments.split(',')
      for (let index = 0; index < firstSplit.length; index++) {
        let drugWrited = firstSplit[index]
        if (drugWrited === '' || drugWrited === ' ') continue
        else if (drugWrited.startsWith(' ') || drugWrited.endsWith(' ')) {
          let drugProcessed = drugWrited.split(' ')[1]
          if (
            !this.current_item.patient.drugs_used_for_actual_treatment.includes(
              drugProcessed
            )
          ) {
            this.current_item.patient.drugs_used_for_actual_treatment.push(
              drugProcessed
            )
          }
        } else {
          if (
            !this.current_item.patient.drugs_used_for_actual_treatment.includes(
              drugWrited
            )
          ) {
            this.current_item.patient.drugs_used_for_actual_treatment.push(
              drugWrited
            )
          }
        }
      }
      if (
        this.current_item.patient.drugs_used_for_actual_treatment.includes(
          'other'
        )
      ) {
        this.current_item.patient.drugs_used_for_actual_treatment.splice(
          this.current_item.patient.drugs_used_for_actual_treatment.indexOf(
            'other'
          ),
          1
        )
      }
    },
    fixCancerSubtypeAtDiagnosisOtherInputField() {
      if (this.cancerSubtypeAtDiagnosisOtherInputField != '') {
        this.current_item.patient.cancer_subtype_at_diagnosis =
          this.cancerSubtypeAtDiagnosisOtherInputField
      }
    },
    setdiagnosisOptions() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (
        this.current_item.typeOfStudy == 'NGS' ||
        this.current_item.typeOfStudy == 'PSP'
      ) {
        return getOptionsFor(this.patientLabels.diagnosis)
      }
      let setting = this.availableBiomarkers.filter((bmk) =>
        bmk.allowedPharmas.find((pharma) =>
          pharma.customSettings.find(
            (cs) =>
              cs.diagnosis != null &&
              cs.sampleTypeId == this.current_item.sampleTypeId &&
              cs.primaryTumorId == this.current_item.primaryTumorId
          )
        )
      )
      let allDiagnosisSettings = this.availableBiomarkers.filter((bmk) =>
        bmk.allowedPharmas.find((pharma) =>
          pharma.customSettings.find(
            (cs) => cs.sampleTypeId == this.current_item.sampleTypeId
          )
        )
      ) // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentDiagnosisConfigs = []
      if (!setting.length && allDiagnosisSettings.length) {
        allDiagnosisSettings
          .filter((setting) =>
            setting.allowedPharmas.map((pharma) =>
              pharma.customSettings.filter(
                (cs) =>
                  cs.sampleTypeId == this.current_item.sampleTypeId &&
                  cs.primaryTumorId == this.current_item.primaryTumorId
              )
            )
          )
          .forEach((result) =>
            result.allowedPharmas.forEach((pharma) =>
              pharma.customSettings.forEach((cs) =>
                this.currentDiagnosisConfigs.push({
                  bmkCode: result.code,
                  sampleTypeId: cs.sampleTypeId,
                  pharmaCode: pharma.code,
                  pharmaName: pharma.name,
                  pharmaId: pharma.id,
                  customName: cs.customName ? cs.customName : result.name,
                  diagnosis: cs.diagnosis,
                  r: cs,
                })
              )
            )
          ) // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return getOptionsFor(this.patientLabels.diagnosis)
      } else if (setting.length != allDiagnosisSettings.length) {
        allDiagnosisSettings
          .filter((setting) =>
            setting.allowedPharmas.map((pharma) =>
              pharma.customSettings.filter(
                (cs) =>
                  cs.sampleTypeId == this.current_item.sampleTypeId &&
                  cs.primaryTumorId == this.current_item.primaryTumorId
              )
            )
          )
          .forEach((result) =>
            result.allowedPharmas.forEach((pharma) =>
              pharma.customSettings.forEach((cs) =>
                this.currentDiagnosisConfigs.push({
                  bmkCode: result.code,
                  sampleTypeId: cs.sampleTypeId,
                  pharmaCode: pharma.code,
                  pharmaName: pharma.name,
                  pharmaId: pharma.id,
                  customName: cs.customName ? cs.customName : result.name,
                  diagnosis: cs.diagnosis,
                  r: cs,
                })
              )
            )
          ) // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //eslint-disable-next-line vue/no-side-effects-in-computed-properties

        return getOptionsFor(this.patientLabels.diagnosis)
      } else {
        setting
          .filter((s) =>
            s.allowedPharmas.map((pharma) =>
              pharma.customSettings.filter(
                (cs) => cs.sampleTypeId == this.current_item.sampleTypeId
              )
            )
          )
          .forEach((result) =>
            result.allowedPharmas.forEach((pharma) =>
              pharma.customSettings.forEach((cs) =>
                this.currentDiagnosisConfigs.push({
                  bmkCode: result.code,
                  sampleTypeId: cs.sampleTypeId,
                  pharmaCode: pharma.code,
                  pharmaName: pharma.name,
                  pharmaId: pharma.id,
                  customName: cs.customName ? cs.customName : result.name,
                  diagnosis: cs.diagnosis,
                  r: cs,
                })
              )
            )
          ) //eslint-disable-next-line vue/no-side-effects-in-computed-properties
        let customDiagnosis = []
        this.currentDiagnosisConfigs.forEach((r) => {
          if (!customDiagnosis.includes(r.diagnosis)) {
            customDiagnosis.push(r.diagnosis)
          }
        })
        if (customDiagnosis.length)
          return getOptionsFor(this.patientLabels.diagnosis).filter((option) =>
            new Set(customDiagnosis).has(option.value)
          )
      }
    },
    resetStudyValues() {
      this.current_item.umbrellaId = null
      this.current_item.primaryTumorId = null
      this.current_item.sampleTypeId = null
      this.current_item.studyTests = []
      this.current_item.patient.diagnosis = null
      this.current_item.patient.diagnosis_stage = null
      this.current_item.patient.cancer_subtype_at_diagnosis = null
      this.current_item.patient.current_stage = null
      this.current_item.patient.drugs_used_for_prior_treatment = []
      this.current_item.patient.previous_treatments = null
      this.current_item.patient.ethinicity = null
    },
    resetStudyValuesWhenChangePrimaryTumor() {
      this.current_item.umbrellaId = null
      this.current_item.studyTests = []
      this.current_item.sampleTypeId = null
      this.current_item.envelope_code = null
    },
    async loadUmbrellaInfo() {
      if (!this.$store.state.studies.umbrellaSettings.length) {
        await this.getUmbrellas()
        await this.getGenes()
        await this.getUmbrellaTestSettings()
        await this.getPreScreeningLabels()
      }
    },
    setAvailableBiomarkers() {
      if (
        !(
          this.current_item.doctor_id &&
          this.current_item.primaryTumorId &&
          this.current_item.sampleTypeId
        )
      )
        return []
      const solidId = this.sampleTypes.find(
        (sampleType) => sampleType.code === 'solid'
      ).id
      let allowedSampleTypesId = [this.current_item.sampleTypeId]
      if (this.current_item.sampleTypeId === solidId)
        allowedSampleTypesId.push(undefined)
      return this.biomarkersSetting
        .filter((biomaker) =>
          biomaker.allowedPrimaryTumors.includes(
            this.currentlySelectedTumor.code
          )
        )
        .map((biomarker) => {
          biomarker = JSON.parse(JSON.stringify(biomarker))
          biomarker.allowedPharmas = biomarker.allowedPharmas.filter((pharma) =>
            pharma.customSettings.some(
              (setting) =>
                setting.sampleTypeId === this.current_item.sampleTypeId &&
                setting.primaryTumorId === this.currentlySelectedTumor.id
            )
          )
          return biomarker
        })
        .filter((biomarker) => biomarker.allowedPharmas.length > 0)
    },
    diagnosisCurrentSelected() {
      let diagnosisSelect =
        document.getElementsByName('diagnosisOptionsCustomSelect').length > 0
          ? document.getElementsByName('diagnosisOptionsCustomSelect')[0].value
          : null
      this.availableBiomarkers = this.setAvailableBiomarkers()
      this.setdiagnosisOptions()
      if (this.current_item.typeOfStudy === 'bmks')
        this.current_item.studyTests = []
      if (diagnosisSelect === null) {
        this.current_item.patient.diagnosis = null
      }
      let sampleId = document.getElementById('sampleTypeSelect').value
      let configs = this.currentDiagnosisConfigs.filter(
        (config) =>
          (config.diagnosis === null || config.diagnosis === diagnosisSelect) &&
          config.sampleTypeId == sampleId
      )
      this.customAvailableBiomarkers = []
      configs.forEach((val) => {
        if (val.r.primaryTumorId == this.current_item.primaryTumorId) {
          let avBmk = this.availableBiomarkers
            .filter((bmk) => bmk.code === val.bmkCode)
            .map((config) => {
              const pharma = config.allowedPharmas.find(
                (ph) => ph.code === val.pharmaCode
              )
              return pharma
            })
            .find((pharma) => pharma !== undefined)
          if (avBmk) avBmk.diagnosisSetting = val
          else {
            avBmk = {}
          }
        }
      })
      this.availableBiomarkers.forEach((bmk) => {
        if (
          bmk.allowedPharmas.find(
            (pharma) =>
              pharma.diagnosisSetting &&
              (pharma.diagnosisSetting.diagnosis === null ||
                pharma.diagnosisSetting.diagnosis === diagnosisSelect) &&
              pharma.diagnosisSetting.sampleTypeId == sampleId &&
              pharma.diagnosisSetting.r.primaryTumorId ==
                this.current_item.primaryTumorId
          )
        )
          this.customAvailableBiomarkers.push(bmk)
      })
      return this.customAvailableBiomarkers
    },
    ...mapActions('panels', [
      'getUmbrellas',
      'getGenesForThisPanel',
      'getGenes',
    ]),
    ...mapActions('studies', ['getUmbrellaTestSettings']),
    setGenesTests(val) {
      this.genesTests = []
      let setting = this.umbrellaSettings
        ? this.umbrellaSettings.find(
            (setting) => setting.code === val[0].testCode
          )
        : undefined
      setting != undefined
        ? setting.genes.forEach((gene) =>
            !this.current_item.studyTests.find(
              (st) => st.genesTestId == gene.gene_id
            )
              ? this.current_item.studyTests.push({
                  genesTestId: gene.gene_id,
                  testCode: gene.genes.code,
                  pharmaId: gene.pharmaId,
                })
              : []
          )
        : undefined
    },
    async getGenesForAnUmbrella(panelId) {
      const data = (await this.axios.get(
              `/api2/panels/panelGenesConfig/${panelId}`
      )).data //prettier-ignore
      return data
    },
    async sendDataToMerck() {
      let currentDoctor = this.options.doctors.filter(
        (doc) => doc.id === this.current_item.doctor_id
      )
      // prettier-ignore
      let data_to_send = {//eslint-disable-line no-unused-vars
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        country: this.currentCountryName,
        telMobile: currentDoctor[0].telMobile,
        email:this.user.email
      }
      const response = await this.axios.get(
        '/api2/auth/token/plataforma_ras_merck'
      )
      var crypt = new JSEncrypt()
      crypt.setKey(response.data) //You can use also setPrivateKey and setPublicKey, they are both alias to setKey
      var enc = crypt.encrypt(JSON.stringify(data_to_send))
      function isProd() {
        if (
          process.env.NODE_ENV === 'production' &&
          window.location.origin !== 'https://pluton-stg.biomakers.net'
        )
          return true
        else return false
      }
      let url = isProd()
        ? 'https://rasmerck.com/registro'
        : 'https://niceapp.cl/merck-regional/app/registro'

      window.location = url + '?d=' + enc
    },
    doctorPickerChanged() {
      this.current_item.pickup_location_id = undefined
    },
    primaryTumorChanged(to) {
      this.current_item.primaryTumorId = to
      this.current_item.sampleTypeId = null
      this.current_item.studyTests = []
      this.current_item.patient.diagnosis = null
    },
    updateSmokerCondition(smokerCondition) {
      this.current_item.patient.smoker = smokerCondition
    },
    updateTimeRange(timeRange) {
      this.current_item.pickup_time_range = timeRange
    },
    updatePreviousTesting(previousTesting) {
      this.current_item.patient.previous_testing = previousTesting
    },
    setNewDefaultValue(object, comparisonValue) {
      if (object != undefined) {
        for (var i = 0; i < object.length; i++) {
          if (object[i].value === comparisonValue) return object[i].value
        }
      }
    },
    checkSquamosousCarcinome() {
      let diagnosisopt = document.getElementsByName(
        'diagnosisOptionsCustomSelect'
      )
      if (diagnosisopt.length > 0) {
        if (
          this.currentlySelectedTumor.code === TUMOR_TYPES.LUNG &&
          diagnosisopt[0].selectedOptions[0]._value === 'squamous_carcinoma'
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkValidOtherField() {
      if (
        this.current_item.additional_information.trim().length === 0 &&
        this.current_item.additional_information.length === 0 &&
        this.current_item.pickup_location_id === null &&
        !this.sampleIsLiquid
      ) {
        // if Other field is empty and it's selected Otro in "Punto de retiro"
        return 'invalid'
      } else if (
        this.current_item.additional_information.length > 0 &&
        this.current_item.additional_information.trim().length === 0 &&
        this.current_item.pickup_location_id === null &&
        !this.sampleIsLiquid
      ) {
        // there are blank spaces and it's selected Otro in Punto de retiro
        return 'invalid'
      } else {
        return 'valid'
      }
    },
    checkStringInAField(array, anString) {
      if (array.includes(anString)) {
        return true
      } else {
        return false
      }
    },
    checkInput(fieldName) {
      //to check input fields values
      var tmp = document.getElementsByClassName('form-control')
      for (let i = 0; i < tmp.length; i++) {
        if (
          tmp[i].offsetParent.innerText.split('\n')[0].split('*')[0] ===
            fieldName &&
          tmp[i].value !== ''
        ) {
          return true
        } else if (i === tmp.length - 1) {
          //if is the last field
          if (
            tmp[i].offsetParent.innerText.split('\n')[0].split('*')[0] ===
            fieldName
          ) {
            return true
          } else {
            return false
          }
        } else {
          continue
        }
      }
    },
    checkDiagnostico(array) {
      //to check if diagnóstico is an obligatory field
      var tmp_diagnostico = document.getElementsByName('diagnostic_field')
      if (tmp_diagnostico.length > 0) {
        if (
          tmp_diagnostico[0].textContent === 'Diagnóstico *' &&
          array.includes('Diagnóstico') == false
        ) {
          array.push('Diagnóstico')
        }
      }
    },
    checkDisabledPickup(array) {
      var tmp_horario = document.getElementsByName('location_hour')
      if (tmp_horario.length > 0) {
        if (
          tmp_horario[0].__vue__.disabled == true &&
          array.includes('Punto de retiro') == true
        ) {
          array.splice(array.indexOf('Punto de retiro'), 1)
        }
      }
    },
    requiresConsentDeclaration() {
      return this.informatedConsentsComputed.length > 0
    },
    isPickupLocationValid() {
      if (!this.$v.$dirty) return true

      if (this.current_item.pickup_location_id === undefined) return false

      return true
    },
    async send() {
      if (
        this.current_item.typeOfStudy === 'bmks' &&
        this.codesValidated.some((code) => !code.valid)
      )
        return this.alertFormError(
          'Por favor, revise que el código del sobre esté correctamente ingresado.'
        )

      if (!this.isValid())
        return this.alertFormError(
          'Por favor, revise que los datos del formulario estén correctos e intente nuevamente.'
        )
      if (!this.current_item.studyTests.length)
        return this.alertFormError(
          'Tiene que elegir por lo menos un estudio a realizar'
        )
      if (
        this.current_item.typeOfStudy === 'PSP' ||
        this.additionalFieldsEnabled
      ) {
        this.addDrugsUsedForPriorTreatments()
        this.addDrugsUsedForActualTreatments()
        this.fixCancerSubtypeAtDiagnosisOtherInputField()
      }
      this.setLoading(true)

      const response = await this.axios({
        method: 'POST',
        url: '/api2/study/',
        data: this.current_item,
      })

      this.setLoading(false)

      const hasAllEnvelopeCode =
        (!this.requiredBiomakerEnvelope || this.current_item.envelope_code) &&
        this.current_item.studyTests.filter(
          (studyTest) =>
            'dontHavePharmaEnvelopeCode' in studyTest &&
            studyTest.dontHavePharmaEnvelopeCode
        ).length == 0

      this.msg = '<div class="d-flex align-items-center">'
      if (this.hasMMRWithBristolTest) {
        this.msg +=
          '<p>Si la muestra ya se encuentra en Biomakers, en breve recibirá el resultado de MMR.<p></div>'
      } else if (!hasAllEnvelopeCode) {
        this.msg += `<p>Si usted cuenta con la muestra, por favor escriba "Biomakers PLUTON#${response.data.id}" en el exterior del sobre que tenga a disposición.<p></div>`
      } else {
        this.msg +=
          '<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="green" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"/></svg>' +
          '<div class="d-flex flex-column ml-3 mt-3">' +
          '<h4 class="modal-title mb-1" id="exampleModalLabel">¡Pedido realizado con exito!</h4>' +
          '<p>Al cerrar será redirigido al inicio.</p></div></div></div>'
      }

      if (response.data && response.data.id) {
        this.showModal = true
      } else {
        this.alertFormError(
          'Hubo un problema con su solicitud, intenta nuevamente mas tarde'
        )
      }
    },
    setStudyTestCustomEnvelopeCode({
      value,
      testIds,
      pharmaId,
      dontHaveEnvelopeCode,
      dontHavePharmaEnvelopeCode,
      isValidCode,
      requiresValidationWithNewApi,
      validateEnvelopeWithNewApi,
    }) {
      this.current_item.studyTests
        .map((currentItem, idx) =>
          testIds.includes(currentItem.testId) &&
          currentItem.pharmaId === pharmaId &&
          (currentItem.requiresValidationWithNewApi ==
            requiresValidationWithNewApi ||
            currentItem.validateEnvelopeWithNewApi ==
              validateEnvelopeWithNewApi)
            ? (idx, currentItem)
            : ''
        )
        .filter(String)
        //eslint-disable-next-line no-unused-vars
        .forEach((item, index) => {
          if (dontHaveEnvelopeCode) {
            const testToModifyIndex = this.current_item.studyTests.findIndex(
              (t) => t.testId === item.testId
            )
            this.current_item.studyTests[testToModifyIndex][
              'dontHavePharmaEnvelopeCode'
            ] = true
            if (!this.requiredBiomakerEnvelope) {
              this.current_item.dont_have_envelope_code = true
            }
          } else if (
            this.current_item.studyTests.find((t) => t.testId === item.testId)
          ) {
            const testToModifyIndex = this.current_item.studyTests.findIndex(
              (t) => t.testId === item.testId
            )
            this.current_item.studyTests[testToModifyIndex][
              'pharmaEnvelopeCode'
            ] = value
            this.current_item.studyTests[testToModifyIndex][
              'dontHavePharmaEnvelopeCode'
            ] = dontHavePharmaEnvelopeCode
            this.current_item['dont_have_envelope_code'] = dontHaveEnvelopeCode
          }
        })
      this.studyTestWithCustomEnvelopeAreValid =
        this.setStudyTestWithCustomEnvelopeAreValid()
      if (
        (this.current_item.typeOfStudy == 'bmks' &&
          this.requiredBiomakerEnvelope == false &&
          !dontHaveEnvelopeCode) ||
        (this.current_item.typeOfStudy != 'bmks' &&
          this.requiredUmbrellaEnvelope &&
          !dontHaveEnvelopeCode)
      ) {
        this.current_item.envelope_code = value
      }
      if (testIds.length > 1) {
        //eslint-disable-next-line no-redeclare
        var index = this.codesValidated.findIndex((opt) =>
          opt.pharmaId === pharmaId && opt.biomarkerIds != undefined
            ? opt.biomarkerIds.every((val) => testIds.includes(val))
            : opt.pharmaId === pharmaId
        )
      } else if (testIds.length === 1) {
        //eslint-disable-next-line no-redeclare
        var index = this.codesValidated.findIndex(
          (opt) => opt.pharmaId === pharmaId && opt.biomarkerId === testIds[0]
        )
      } else {
        //eslint-disable-next-line no-redeclare
        var index = null
      }
      this.codesValidated[index].valid = isValidCode
    },
    setStudyTestWithCustomEnvelopeAreValid() {
      if (this.current_item.typeOfStudy === 'bmks') {
        return this.current_item.studyTests
          .filter((st) =>
            this.pharmasWithCustomEnvelope
              .map((ph) => ph.id)
              .includes(st.pharmaId)
          )
          .every((tests) => tests.pharmaEnvelopeCode)
      }
    },
  },
}
</script>

<style lang="scss">
.vdp-datepicker .form-control:disabled,
.vdp-datepicker .form-control[readonly] {
  background-color: #fff !important;
  cursor: pointer !important;
}

.main-content-container > *:last-child {
  margin-bottom: 2rem;
}

.envelope {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  column-gap: 2rem;

  :first-child {
    grid-column: 1 / 3;
  }
}
.container-cstm {
  padding-left: 40%;
  display: flex;
  position: absolute;
  .btn-group input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
    .btn-check {
      display: inline-block;
      font-family: sans-serif, Arial;
      font-size: 16px;
    }
  }
  .btn-outline-primary {
    background-color: var(--purple-light);
    border-color: var(--purple-light);
    margin-right: 3%;
  }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
  }
  .btn-group input[type='radio'] + label {
    border-radius: 10rem;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 11rem;
  }
  .btn-group input[type='radio']:checked + label {
    background-color: var(--purple);
    border-color: var(--purple);
    border-radius: 10rem;
    //border-radius: 10px;
  }
}
.container-cstm-3 {
  padding-left: 30%;
  display: flex;
  position: absolute;
  .btn-group input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
    .btn-check {
      display: inline-block;
      font-family: sans-serif, Arial;
      font-size: 16px;
    }
  }
  .btn-outline-primary {
    background-color: var(--purple-light);
    border-color: var(--purple-light);
    margin-right: 3%;
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
  }
  .btn-group input[type='radio'] + label {
    border-radius: 10rem;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 11rem;
  }
  .btn-group input[type='radio']:checked + label {
    background-color: var(--purple);
    border-color: var(--purple);
    border-radius: 10rem;
    //border-radius: 10px;
  }
}
.modal {
  z-index: 2000;
}
.resultList {
  max-height: 200px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
  border: 1px solid #ccc; /* Añade un borde para mayor claridad */
  padding: 8px;
}
.custom-form-btn {
  height: 2.5rem;
  width: 2rem;
}
.custom-icon-x {
  right: 3rem;
}
no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}
</style>
