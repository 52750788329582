<template>
  <div>
    <form @submit="handleSave">
      <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} país`">
        <button
          type="submit"
          :disabled="!isValid || !isDirty"
          class="btn btn-pill btn-accent"
        >
          {{ isNew ? 'Crear' : 'Actualizar' }}
        </button>
        <button
          v-if="!isNew"
          type="button"
          :disabled="!isValid"
          class="btn btn-pill btn-secondary"
          @click="handleToggleStatus"
        >
          {{ isEnabled ? 'Deshabilitar' : 'Habilitar' }}
        </button>
        <button
          type="button"
          class="btn btn-pill btn-secondary"
          @click="handleDiscard"
        >
          {{ isDirty ? 'Descartar' : 'Volver' }}
        </button>
      </management-header>

      <d-card class="mb-4">
        <d-card-body>
          <v-input
            v-model="countryName"
            :error="errors.name"
            name="name"
            placeholder="Nombre del país"
          />
          <div class="form-group">
            <label>Laboratorio por defecto:</label>
            <d-form-select v-model="countryDefaultLab" :options="labOptions">
              <option :value="null" disabled>Seleccione un laboratorio</option>
            </d-form-select>
            <small class="form-text text-muted">
              Este laboratorio se va a asignar cuando un doctor cree un estudio
              para este país.
            </small>
          </div>
        </d-card-body>
      </d-card>
      <d-card class="mb-4 setting">
        <d-card-body>
          <h5>Umbrellas configurados</h5>
          <div class="header">
            <div class="form-group">
              <label>Umbrella:</label>
              <d-form-select
                v-model="settingUmbrella"
                :options="umbrellaOptions"
              >
                <option :value="null">Seleccione un Umbrella</option>
              </d-form-select>
            </div>
            <div class="form-group">
              <label>Farmacéutica:</label>
              <d-form-select
                v-model="settingUmbrellaPharma"
                :options="umbrellaPharmaOptions"
              >
                <option :value="null">Seleccione una farmacéutica</option>
              </d-form-select>
            </div>

            <label>Estado</label>
            <div class="form-group">
              <label>Tipo de estudio:</label>
              <d-form-select
                v-model="umbrellaSettingTypeOfStudy"
                :options="typeOfStudiesOptions"
              >
                <option :value="null">Seleccione un tipo de estudio</option>
              </d-form-select>
            </div>
            <d-checkbox v-model="umbrellaSettingRequiresValidationWithNewApi"
              >Requiere validacion sobre</d-checkbox
            >
            <button
              type="button"
              class="btn btn-pill btn-secondary"
              :disabled="
                settingUmbrella === null || settingUmbrellaPharma === null
              "
              @click="addCountryUmbrellaSetting"
            >
              Agregar configuración
            </button>
          </div>
          <hr />
          <!-- Visualización de las configuraciones por umbrella -->
          <template v-for="(setting, idx) in countryUmbrellaSettings">
            <div :key="setting.id" class="result">
              <span>{{ umbrellasById[setting.umbrellaId] }}</span>
              <span>{{ pharmasById[setting.pharmaId] }}</span>
              <span :class="setting.rowStatus === 'E' ? 'enabled' : 'disabled'">
                {{ setting.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
              </span>
              <d-form-select
                :value="setting.typeOfStudyId"
                :options="typeOfStudiesOptions"
                @change="
                  ($event) =>
                    updateUmbrellaSettingTypeOfStudyId({
                      setting: setting,
                      newValue: $event,
                    })
                "
              >
                <option value="null" disabled>
                  Seleccione un tipo de estudio
                </option></d-form-select
              >
              <d-checkbox
                :v-model="setting.requiresValidationWithNewApi"
                :checked="!!setting.requiresValidationWithNewApi"
                @change="
                  patchUmbrellaSettingRequiresValidationWithNewApi({
                    settingId: setting.id,
                    value:
                      setting.requiresValidationWithNewApi == null
                        ? true
                        : !setting.requiresValidationWithNewApi,
                  })
                "
              >
                <span
                  :class="
                    setting.requiresValidationWithNewApi == false ||
                    setting.requiresValidationWithNewApi == null
                      ? 'disabled'
                      : 'enabled'
                  "
                >
                  {{
                    setting.requiresValidationWithNewApi == false ||
                    setting.requiresValidationWithNewApi == null
                      ? 'Desactivado'
                      : 'Activado'
                  }}
                </span></d-checkbox
              >
              <d-button-group>
                <button
                  class="material-icons"
                  type="button"
                  @click="() => handleOpenUmbrellaModal(idx)"
                >
                  add
                </button>
                <button
                  class="material-icons"
                  type="button"
                  @click="() => removeUmbrellaCountrySetting(setting)"
                >
                  delete
                </button>
              </d-button-group>
            </div>
            <hr :key="`${idx}-hr`" />
          </template>
        </d-card-body>
      </d-card>

      <!-- configuración de los biomarcadores -->
      <d-card class="mb-4 setting">
        <d-card-body>
          <h5>Biomarcadores configurados</h5>
          <div class="header">
            <div class="form-group">
              <label>Biomarcador:</label>
              <d-form-select
                v-model="settingBiomarker"
                :options="biomarkerOptions"
              >
                <option :value="null">Seleccione un biomarcador</option>
              </d-form-select>
            </div>
            <div class="form-group">
              <label>Farmacéutica:</label>
              <d-form-select v-model="settingPharma" :options="pharmaOptions">
                <option :value="null">Seleccione una farmacéutica</option>
              </d-form-select>
            </div>
            <span>Estado</span>
            <div class="form-group">
              <label>Tipo de estudio:</label>
              <d-form-select
                v-model="settingTypeOfStudy"
                :options="typeOfStudiesOptions"
              >
                <option :value="null">Seleccione un tipo de estudio</option>
              </d-form-select>
            </div>
            <d-checkbox v-model="settingRequiresValidationWithNewApi"
              >Requiere validacion sobre</d-checkbox
            >
            <button
              type="button"
              class="btn btn-pill btn-secondary"
              :disabled="settingBiomarker === null || settingPharma === null"
              @click="addCountrySetting"
            >
              Agregar configuración
            </button>
          </div>
          <hr />
          <template v-for="(setting, idx) in countrySettings">
            <div :key="setting.id" class="result">
              <span>{{ biomarkersById[setting.biomarkerId] }}</span>
              <span>{{ pharmasById[setting.pharmaId] }}</span>

              <d-checkbox
                :v-model="setting.rowStatus"
                :checked="setting.rowStatus == 'E'"
                @change="
                  patchSettingRowStatus({
                    settingId: setting.id,
                    value: setting.rowStatus == 'E' ? 'D' : 'E',
                  })
                "
              >
                <span
                  :class="setting.rowStatus === 'E' ? 'enabled' : 'disabled'"
                >
                  {{
                    setting.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado'
                  }}
                </span>
              </d-checkbox>
              <d-form-select
                :value="setting.typeOfStudyId"
                :options="typeOfStudiesOptions"
                @change="
                  ($event) =>
                    updateSettingTypeOfStudyId({
                      setting: setting,
                      newValue: $event,
                    })
                "
              >
                <option value="null" disabled>
                  Seleccione un tipo de estudio
                </option>
              </d-form-select>
              <d-checkbox
                :v-model="setting.requiresValidationWithNewApi"
                :checked="!!setting.requiresValidationWithNewApi"
                @change="
                  patchSettingRequiresValidationWithNewApi({
                    settingId: setting.id,
                    value:
                      setting.requiresValidationWithNewApi == null
                        ? true
                        : !setting.requiresValidationWithNewApi,
                  })
                "
              >
                <span
                  :class="
                    setting.requiresValidationWithNewApi == false ||
                    setting.requiresValidationWithNewApi == null
                      ? 'disabled'
                      : 'enabled'
                  "
                >
                  {{
                    setting.requiresValidationWithNewApi == false ||
                    setting.requiresValidationWithNewApi == null
                      ? 'Desactivado'
                      : 'Activado'
                  }}
                </span></d-checkbox
              >

              <d-button-group>
                <d-button
                  :id="`addConfig-${idx}`"
                  type="button"
                  @click="() => handleOpenModal(idx)"
                >
                  <span class="material-icons">add</span>
                </d-button>
                <d-button
                  :id="`addCaps-${idx}`"
                  type="button"
                  @click="() => handleOpenCapsModal(setting, idx)"
                  ><span class="material-icons">pan_tool</span></d-button
                >
                <d-button
                  :id="`deleteConfig-${idx}`"
                  type="button"
                  @click="() => removeCountrySetting(setting)"
                >
                  <span class="material-icons">delete</span>
                </d-button>
              </d-button-group>

              <d-tooltip :target="`#addConfig-${idx}`" :triggers="['hover']"
                >Agregue una configuracion</d-tooltip
              >
              <d-tooltip :target="`#addCaps-${idx}`" :triggers="['hover']"
                >Gestione los topes para esta configuracion</d-tooltip
              >
              <d-tooltip :target="`#deleteConfig-${idx}`" :triggers="['hover']"
                >Borre una configuracion</d-tooltip
              >
            </div>
            <hr :key="`${idx}-hr`" />
          </template>
        </d-card-body>
      </d-card>
    </form>
    <d-modal
      v-if="capsModal.show"
      size="lg"
      class="custom"
      @close="() => (capsModal.show = false)"
    >
      <d-modal-header>
        <d-modal-title>Gestionar configuracion de los topes</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <d-alert
          v-if="msg"
          :show="timeUntilDismissed"
          :theme="hasErrors ? 'danger' : 'success'"
          dismissible
          @alert-dismissed="timeUntilDismissed = 0"
          @alert-dismiss-countdown="handleTimeChange"
        >
          {{ msg }} {{ timeUntilDismissed }}
        </d-alert>
        <div class="d-card-body">
          <div class="form-group">
            <label>Nombre del tope</label>
            <v-input
              v-model="capsName"
              name="capsName"
              placeholder="Ingrese el nombre del tope"
            />

            <label>Valor de testeo de inicio:</label>
            <v-input
              v-model="capsInitialValue"
              name="caps"
              placeholder="Ingrese el valor inicial del topeo (0 o dejar vacio por default para topes sin asociacion)"
              type="number"
            />

            <label>Número de testeos mensual:</label>
            <v-input
              v-model="caps"
              name="caps"
              placeholder="Ingrese el valor de corte"
              type="number"
            />
            <h6 class="text text-danger">
              <span class="enabled">
                Testeos realizados: {{ capsNumberOfTestsDone }}
              </span>
              / Topeo mensual:
              <!-- eslint-disable -->
              {{
                (capsInitialValue <= 0 || capsInitialValue === null) == true
                  ? caps - capsInitialValue
                  : caps - capsInitialValue + 1
              }}
            </h6>
          </div>
          <div v-if="associatedCaps.id != null">
            <d-container class="table">
              <h6 class="text-center">Configuración de tope asociado</h6>
              <d-row class="mx-4 mb-4" fluid>
                <d-col class="border-custom position-relative"
                  ><p>Tope</p>
                  <p>
                    {{ associatedCaps.capsName }}
                  </p>
                </d-col>
                <d-col class="border-custom position-relative"
                  ><p>Valor inicial</p>
                  <p>
                    {{
                      associatedCaps.initialValue != null
                        ? associatedCaps.initialValue
                        : 0
                    }}
                  </p></d-col
                >
                <d-col class="border-custom position-relative">
                  <p>Tope de testeos</p>
                  <p>{{ associatedCaps.caps }}</p></d-col
                >
                <d-col class="border-custom position-relative">
                  <p>N° de Testeos</p>
                  <p>
                    {{
                      associatedCaps.initialValue === null ||
                      associatedCaps.initialValue <= 0
                        ? associatedCaps.caps - associatedCaps.initialValue
                        : associatedCaps.caps - associatedCaps.initialValue + 1
                    }}
                  </p></d-col
                >
              </d-row>
            </d-container>
            <!-- eslint-enable -->
          </div>
          <d-button
            type="button"
            class="btn-accent"
            :disabled="
              !$store.state.countries.caps.capsName ||
              !$store.state.countries.caps.caps
            "
            @click="saveCapsConfigurationAndSetMessage()"
            >{{ isCapsNew ? 'Guardar Tope ' : 'Actualizar Tope' }}</d-button
          >
          <d-button
            v-if="!isCapsNew"
            type="button"
            theme="danger"
            @click="deleteCapsConfig().then(() => (capsModal.show = false))"
            >Borrar configuracion
          </d-button>
        </div>
      </d-modal-body>
    </d-modal>
    <d-modal
      v-if="modal.show"
      size="lg"
      class="custom"
      @close="handleCloseModal"
    >
      <d-modal-header>
        <d-modal-title>Configuración por tumor</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <div class="header">
          <div class="form-group">
            <label>Tumor:</label>
            <d-form-select
              v-model="customSettingTumor"
              :options="modal.tumorOptions"
            >
              <option :value="null" disabled>Seleccione un tumor</option>
            </d-form-select>
          </div>
          <div class="form-group">
            <label>Tipo de muestra:</label>
            <d-form-select
              id="sampleTypeBiomarker"
              v-model="customSettingSampleType"
              :options="sampleTypesOptions"
              @change="isDiagnosisIsAlreadySelected"
            >
              <option :value="null" :disabled="!customSettingTumor">
                Solida y Líquida
              </option>
            </d-form-select>
          </div>
          <v-input
            v-model="customSettingDocumentation"
            name="additional_documentation"
            placeholder="Documentación"
          />
          <div class="form-group">
            <label>Tipo de Diagnóstico:</label>
            <d-form-select
              id="diagnosisField"
              v-model="customSettingDiagnosis"
              :options="diagnosisOptions"
              @change="isDiagnosisIsAlreadySelected"
            >
              <option :value="null" default>Todos los diagnósticos</option>
            </d-form-select>
          </div>
          <v-input
            v-model="customSettingDiagnosisName"
            name="customName"
            placeholder="Nombre personalizado"
          />
          <button
            type="button"
            class="btn btn-pill btn-accent"
            :disabled="!customSettingSampleType"
            @click="() => addCountryCustomSetting(modal.settingIndex)"
          >
            Agregar
          </button>
        </div>
        <!-- <p v-if="doesPrimaryTumorHaveNullSettings(customSettingTumor)">
          No se puede agregar una configuración nueva para este
          <strong>tipo de tumor</strong> hasta que se seleccione un
          <strong>tipo de muestra</strong> para la configuración ya existente.
        </p> -->
        <p v-if="!sampleTypesOptionsForPrimaryTumor(customSettingTumor).length">
          Ya están configurados todos los
          <strong>tipos de muestra</strong> posibles para este
          <strong>tipo de tumor</strong>.
        </p>
        <hr />
        <div v-if="modal.settingIndex >= 0">
          <div
            v-for="(custom, idx) in countrySettings[modal.settingIndex]
              .customSettings"
            :key="idx"
            class="result"
          >
            <d-form-select
              :value="custom.primaryTumorId"
              :options="modal.tumorOptions"
              disabled
              @change="
                setCountryCustomSettings({
                  settingIndex: modal.settingIndex,
                  index: idx,
                  key: 'primaryTumorId',
                  value: $event,
                })
              "
            />
            <d-form-select
              :value="custom.sampleTypeId"
              :options="
                sampleTypesOptionsForPrimaryTumor(
                  custom.primaryTumorId,
                  custom.sampleTypeId
                )
              "
              disabled
              @change="
                setCountryCustomSettings({
                  settingIndex: modal.settingIndex,
                  index: idx,
                  key: 'sampleTypeId',
                  value: $event,
                })
              "
            >
              <option :value="null">Solida y Líquida</option>
            </d-form-select>
            <v-input
              :value="custom.documentation"
              name="additional_documentation"
              placeholder="Documentación"
              @input="
                setCountryCustomSettings({
                  settingIndex: modal.settingIndex,
                  index: idx,
                  key: 'documentation',
                  value: $event,
                })
              "
            />
            <d-form-select
              :value="custom.diagnosis"
              :options="diagnosisOptions"
              disabled
              @change="
                setCountryCustomSettings({
                  settingIndex: modal.settingIndex,
                  index: idx,
                  key: 'diagnosis',
                  value: $event,
                })
              "
            >
              <option :value="null">Todos los diagnósticos</option>
            </d-form-select>
            <v-input
              :value="custom.customName"
              name="custom_name"
              placeholder="Nombre Personalizado"
              disabled
              @input="
                setCountryCustomSettings({
                  settingIndex: modal.settingIndex,
                  index: idx,
                  key: 'diagnosis',
                  value: $event,
                })
              "
            />
            <button
              class="material-icons"
              type="button"
              @click="
                () =>
                  removeCountryCustomSettings({
                    settingIndex: modal.settingIndex,
                    index: idx,
                  })
              "
            >
              delete
            </button>
          </div>
        </div>
      </d-modal-body>
    </d-modal>

    <!-- modal of umbrellas custom setting configuration -->

    <d-modal
      v-if="umbrellaModal.show"
      size="lg"
      class="custom"
      @close="handleCloseUmbrellaModal"
    >
      <d-modal-header>
        <d-modal-title>Configuración por tumor</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <div class="header">
          <div class="form-group">
            <label>Tumor:</label>
            <d-form-select
              v-model="umbrellaCustomSettingTumor"
              :options="umbrellaModal.tumorOptions"
            >
              <option :value="null" disabled>Seleccione un tumor</option>
            </d-form-select>
          </div>
          <div class="form-group">
            <label>Tipo de muestra:</label>
            <d-form-select
              v-model="umbrellaCustomSettingSampleType"
              :options="
                sampleTypesOptionsForUmbrellaPrimaryTumor(
                  umbrellaCustomSettingTumor
                )
              "
              :disabled="
                isUmbrellaPrimaryTumorLocked(umbrellaCustomSettingTumor)
              "
            >
              <option :value="null" disabled>
                Seleccione un tipo de muestra
              </option>
            </d-form-select>
          </div>
          <v-input
            v-model="umbrellaCustomSettingDocumentation"
            name="additional_documentation"
            placeholder="Documentación"
            :disabled="isUmbrellaPrimaryTumorLocked(customSettingTumor)"
          />
          <button
            type="button"
            class="btn btn-pill btn-accent"
            :disabled="!isUmbrellaCustomSettingValid"
            @click="
              () => addCountryUmbrellaCustomSetting(umbrellaModal.settingIndex)
            "
          >
            Agregar
          </button>
        </div>
        <p v-if="doesUmbrellaPrimaryTumorHaveNullSettings(customSettingTumor)">
          No se puede agregar una configuración nueva para este
          <strong>tipo de tumor</strong> hasta que se seleccione un
          <strong>tipo de muestra</strong> para la configuración ya existente.
        </p>
        <p
          v-if="
            !sampleTypesOptionsForUmbrellaPrimaryTumor(
              umbrellaCustomSettingTumor
            ).length
          "
        >
          Ya están configurados todos los
          <strong>tipos de muestra</strong> posibles para este
          <strong>tipo de tumor</strong>.
        </p>
        <hr />
        <div v-if="umbrellaModal.settingIndex >= 0">
          <div
            v-for="(custom, idx) in countryUmbrellaSettings[
              umbrellaModal.settingIndex
            ].umbrellaCustomSetting"
            :key="idx"
            class="result"
          >
            <d-form-select
              :value="custom.primaryTumorId"
              :options="umbrellaModal.tumorOptions"
              @change="
                setCountryUmbrellaCustomSettings({
                  settingIndex: umbrellaModal.settingIndex,
                  index: idx,
                  key: 'primaryTumorId',
                  value: $event,
                })
              "
            />
            <d-form-select
              :value="custom.sampleTypeId"
              :options="
                sampleTypesOptionsForUmbrellaPrimaryTumor(
                  custom.primaryTumorId,
                  custom.sampleTypeId
                )
              "
              @change="
                setCountryUmbrellaCustomSettings({
                  settingIndex: umbrellaModal.settingIndex,
                  index: idx,
                  key: 'sampleTypeId',
                  value: $event,
                })
              "
            >
              <option :value="null" disabled>
                Seleccione un tipo de muestra
              </option>
            </d-form-select>
            <v-input
              :value="custom.documentation"
              name="additional_documentation"
              placeholder="Documentación"
              @input="
                setCountryUmbrellaCustomSettings({
                  settingIndex: umbrellaModal.settingIndex,
                  index: idx,
                  key: 'documentation',
                  value: $event,
                })
              "
            />
            <button
              class="material-icons"
              type="button"
              @click="
                () =>
                  removeCountryUmbrellaCustomSettings({
                    settingIndex: umbrellaModal.settingIndex,
                    index: idx,
                  })
              "
            >
              delete
            </button>
          </div>
        </div>
      </d-modal-body>
    </d-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import VInput from '@/components/Form/VInput.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

import { ROW_STATUS } from '@/utils/constants'

export default {
  components: {
    VInput,
    ManagementHeader,
  },
  props: {
    countryId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      msg: '',
      timeUntilDismissed: 0,
      duration: 3,
      modal: {
        settingIndex: -1,
        tumorOptions: [],
        show: false,
      },
      umbrellaModal: {
        settingIndex: -1,
        tumorOptions: [],
        show: false,
      },
      AMEModal: {
        settingIndex: -1,
        tumorOptions: [],
        show: false,
      },
      capsModal: {
        settingIndex: -1,
        capsOptions: [],
        show: false,
      },
      diagnosisIsAlreadySelected: false,
    }
  },
  computed: {
    ...mapState('country', ['caps']),
    ...mapGetters('biomarkers', ['biomarkers', 'biomarkersEnabled']),
    ...mapGetters('primaryTumors', ['primaryTumors', 'primaryTumorsEnabled']),
    ...mapGetters('pharmas', ['pharmas', 'pharmasEnabled']),
    ...mapGetters('labs', ['labs']),
    ...mapGetters('countries', [
      'isNew',
      'isValid',
      'isDirty',
      'hasErrors',
      'errors',
      'sampleTypes',
      'typeOfStudies',
      'isCustomSettingValid',
      'isUmbrellaCustomSettingValid',
      'isCapsNew',
    ]),
    ...mapGetters('panels', ['umbrella', 'umbrellas', 'umbrellasEnabled']),
    isEnabled() {
      return (
        this.$store.state.countries.country.rowStatus === ROW_STATUS.ENABLED
      )
    },
    typeOfStudiesOptions() {
      return this.typeOfStudies.map((type) => ({
        value: type.id,
        text: type.name,
      }))
    },
    capsName: {
      get() {
        return this.$store.state.countries.caps.capsName
      },
      set(val) {
        this.setCapsName(val)
      },
    },
    caps: {
      get() {
        return this.$store.state.countries.caps.caps
      },
      set(val) {
        this.setCaps(val)
      },
    },
    associatedCaps() {
      return this.$store.state.countries.associatedCaps
    },
    capsNumberOfTestsDone() {
      return this.$store.state.countries.caps.numberOfTestsDone
    },
    capsInitialValue: {
      get() {
        return this.$store.state.countries.caps.initialValue
      },
      set(val) {
        this.setCapsInitialValue(val)
      },
    },
    countryName: {
      get() {
        return this.$store.state.countries.country.name
      },
      set(name) {
        this.setCountryName(name)
      },
    },
    countryDefaultLab: {
      get() {
        return this.$store.state.countries.country.defaultLabId
      },
      set(defaultLabId) {
        this.setCountryDefaultLab(defaultLabId)
      },
    },
    settingBiomarker: {
      get() {
        return this.$store.state.countries.setting.biomarkerId
      },
      set(biomarkerId) {
        this.setSettingBiomarker(biomarkerId)
      },
    },
    settingTypeOfStudy: {
      get() {
        return this.$store.state.countries.setting.typeOfStudyId
      },
      set(typeOfStudyId) {
        this.setSettingTypeOfStudyId(typeOfStudyId)
      },
    },
    umbrellaSettingTypeOfStudy: {
      get() {
        return this.$store.state.countries.countryUmbrellaSetting.typeOfStudyId
      },
      set(typeOfStudyId) {
        this.setUmbrellaSettingTypeOfStudyId(typeOfStudyId)
      },
    },
    settingPharma: {
      get() {
        return this.$store.state.countries.setting.pharmaId
      },
      set(pharmaId) {
        this.setSettingPharma(pharmaId)
      },
    },
    settingUmbrellaPharma: {
      get() {
        return this.$store.state.countries.countryUmbrellaSetting.pharmaId
      },
      set(pharmaId) {
        this.setUmbrellaSettingPharma(pharmaId)
      },
    },
    settingRequiresValidationWithNewApi: {
      get() {
        return this.$store.state.countries.setting.requiresValidationWithNewApi
      },
      set(val) {
        this.setSettingRequiresValidationWithNewApi(val)
      },
    },
    AMESettingRequiresValidationWithNewApi: {
      get() {
        return this.$store.state.countries.AMESetting
          .requiresValidationWithNewApi
      },
      set(val) {
        this.setAMESettingRequiresValidationWithNewApi(val)
      },
    },
    umbrellaSettingRequiresValidationWithNewApi: {
      get() {
        return this.$store.state.countries.countryUmbrellaSetting
          .requiresValidationWithNewApi
      },
      set(val) {
        this.setUmbrellaSettingRequiresValidationWithNewApi(val)
      },
    },
    settingUmbrella: {
      get() {
        return this.$store.state.countries.countryUmbrellaSetting.umbrellaId
      },
      set(umbrellaId) {
        this.setUmbrellaSettingUmbrellaId(umbrellaId)
      },
    },
    customSettingTumor: {
      get() {
        return this.$store.state.countries.customSetting.primaryTumorId
      },
      set(primaryTumorId) {
        this.setCustomSettingTumor(primaryTumorId)
      },
    },
    customSettingSampleType: {
      get() {
        return this.$store.state.countries.customSetting.sampleTypeId
      },
      set(sampleTypeId) {
        this.setCustomSettingSampleType(sampleTypeId)
      },
    },
    AMECustomSettingSampleType: {
      get() {
        return this.$store.state.countries.AMECustomSetting.sampleTypeId
      },
      set(sampleTypeId) {
        this.setAMECustomSettingSampleType(sampleTypeId)
      },
    },
    customSettingDocumentation: {
      get() {
        return this.$store.state.countries.customSetting.documentation
      },
      set(documentation) {
        this.setCustomSettingDocumentation(documentation)
      },
    },
    AMECustomSettingDocumentation: {
      get() {
        return this.$store.state.countries.AMECustomSetting.documentation
      },
      set(documentation) {
        this.setAMECustomSettingDocumentation(documentation)
      },
    },
    umbrellaCustomSettingTumor: {
      get() {
        return this.$store.state.countries.umbrellaCustomSetting.primaryTumorId
      },
      set(primaryTumorId) {
        this.setUmbrellaCustomSettingTumor(primaryTumorId)
      },
    },
    umbrellaCustomSettingSampleType: {
      get() {
        return this.$store.state.countries.umbrellaCustomSetting.sampleTypeId
      },
      set(sampleTypeId) {
        this.setUmbrellaCustomSettingSampleType(sampleTypeId)
      },
    },
    umbrellaCustomSettingDocumentation: {
      get() {
        return this.$store.state.countries.umbrellaCustomSetting.documentation
      },
      set(documentation) {
        this.setUmbrellaCustomSettingDocumentation(documentation)
      },
    },
    countrySettings() {
      return this.$store.state.countries.country.settings
    },
    countryUmbrellaSettings() {
      return this.$store.state.countries.country.countryUmbrellaSetting
    },
    biomarkersById() {
      return this.getNameById(this.biomarkers)
    },
    pharmasById() {
      return this.getNameById(this.pharmas)
    },
    umbrellasById() {
      return this.getNameById(this.umbrellas)
    },
    labOptions() {
      return (this.labs || [])
        .filter(
          (lab) =>
            lab.rowStatus === ROW_STATUS.ENABLED ||
            this.settingDefaultLab === lab.id
        )
        .map((lab) => ({
          value: lab.id,
          text: lab.name,
          disabled:
            this.settingDefaultLab === lab.id &&
            lab.rowStatus !== ROW_STATUS.ENABLED,
        }))
    },
    umbrellaOptions() {
      return this.umbrellasEnabled.map((umbrella) => ({
        value: umbrella.id,
        text: umbrella.name,
      }))
    },
    AMEOptions() {
      const usedBiomarkers = this.countryRareDiseasesSettings
        .filter((setting) => setting.pharmaId === this.AMEsettingPharma)
        .map((setting) => setting.biomarkerId)

      return this.biomarkersEnabled
        .filter((biomarker) => !usedBiomarkers.includes(biomarker.id))
        .map((biomarker) => ({ value: biomarker.id, text: biomarker.name }))
    },
    biomarkerOptions() {
      const usedBiomarkers = this.countrySettings
        .filter((setting) => setting.pharmaId === this.settingPharma)
        .map((setting) => setting.biomarkerId)

      return this.biomarkersEnabled
        .filter((biomarker) => !usedBiomarkers.includes(biomarker.id))
        .map((biomarker) => ({ value: biomarker.id, text: biomarker.name }))
    },
    pharmaOptions() {
      const usedPharmas = this.countrySettings
        .filter((setting) => setting.biomarkerId === this.settingBiomarker)
        .map((setting) => setting.pharmaId)

      return this.pharmasEnabled
        .filter((pharma) => !usedPharmas.includes(pharma.id))
        .map((pharma) => ({ value: pharma.id, text: pharma.name }))
    },
    AMEPharmaOptions() {
      const usedPharmas = this.countryRareDiseasesSettings
        .filter((setting) => setting.pharmaId === this.AMEsettingPharma)
        .map((setting) => setting.pharma)

      return this.pharmasEnabled
        .filter((pharma) => !usedPharmas.includes(pharma.id))
        .map((pharma) => ({ value: pharma.id, text: pharma.name }))
    },
    umbrellaPharmaOptions() {
      const usedPharmas = this.countryUmbrellaSettings
        .filter((setting) => setting.umbrellaId === this.settingUmbrella)
        .map((setting) => setting.pharmaId)

      return this.pharmasEnabled
        .filter((pharma) => !usedPharmas.includes(pharma.id))
        .map((pharma) => ({ value: pharma.id, text: pharma.name }))
    },
    sampleTypesOptions() {
      return this.sampleTypes.map((sampleType) => ({
        value: sampleType.id,
        text: sampleType.label,
      }))
    },
    diagnosisOptions() {
      return JSON.parse(
        JSON.stringify(this.$store.state.countries.diagnosisOptions)
      )
    },
    sampleTypesOptionsForPrimaryTumor() {
      return (primaryTumorId, includeSampleTypeId = null) => {
        if (!primaryTumorId) return this.sampleTypesOptions
        const usedSampleTypeIds = this.countrySettings[
          this.modal.settingIndex
        ].customSettings
          .filter((setting) => setting.primaryTumorId === primaryTumorId)
          .map((setting) => setting.sampleTypeId)
        return this.sampleTypesOptions.filter(
          (option) =>
            !usedSampleTypeIds.includes(option.value) ||
            option.value === includeSampleTypeId
        )
      }
    },
    sampleTypesOptionsForUmbrellaPrimaryTumor() {
      return (primaryTumorId, includeSampleTypeId = null) => {
        if (!primaryTumorId) return this.sampleTypesOptions
        const usedSampleTypeIds = this.countryUmbrellaSettings[
          this.umbrellaModal.settingIndex
        ].umbrellaCustomSetting
          .filter((setting) => setting.primaryTumorId === primaryTumorId)
          .map((setting) => setting.sampleTypeId)
        return this.sampleTypesOptions.filter(
          (option) =>
            !usedSampleTypeIds.includes(option.value) ||
            option.value === includeSampleTypeId
        )
      }
    },
    doesPrimaryTumorHaveNullSettings() {
      return (primaryTumorId) => {
        return !!this.countrySettings[
          this.modal.settingIndex
        ].customSettings.find(
          (setting) =>
            setting.primaryTumorId === primaryTumorId &&
            setting.sampleTypeId === null
        )
      }
    },
    doesUmbrellaPrimaryTumorHaveNullSettings() {
      return (primaryTumorId) => {
        return !!this.countryUmbrellaSettings[
          this.umbrellaModal.settingIndex
        ].umbrellaCustomSetting.find(
          (setting) =>
            setting.primaryTumorId === primaryTumorId &&
            setting.sampleTypeId === null
        )
      }
    },
    customSettingDiagnosis: {
      get() {
        return this.$store.state.countries.country.settings[
          this.modal.settingIndex
        ].customSettings
      },
      set(val) {
        this.setCustomSettingDiagnosis(val)
      },
    },
    customSettingDiagnosisName: {
      get() {
        return this.$store.state.countries.country.settings[
          this.modal.settingIndex
        ].customSettings.customName
      },
      set(name) {
        this.setCustomSettingDiagnosisName(name)
      },
    },
  },

  async mounted() {
    await this.getDiagnosisOptions()
    await this.getSampleTypes()
    this.getBiomarkers()
    this.getPharmas()
    this.getLabs()
    this.getPrimaryTumors()
    await this.getCountries()
    await this.setCountry(this.countryId)
    this.clearSetting()
    await this.getUmbrellas()
    await this.getTypesOfStudy()
  },
  methods: {
    ...mapActions('countries', [
      'setSettingTypeOfStudyId',
      'setUmbrellaSettingTypeOfStudyId',
      'updateSettingTypeOfStudyId',
      'updateUmbrellaSettingTypeOfStudyId',
      'getTypesOfStudy',
      'getCountrySetting',
      'getDiagnosisOptions',
      'setCustomSettingDiagnosis',
      'setCustomSettingDiagnosisName',
      'getSampleTypes',
      'getCountries',
      'saveCountry',
      'setCountry',
      'setCountryName',
      'setCountrySettings',
      'setCountryDefaultLab',
      'setSettingBiomarker',
      'setSettingPharma',
      'setAMESettingBiomarker',
      'setAMESettingPharma',
      'setSettingRequiresValidationWithNewApi',
      'setAMESettingRequiresValidationWithNewApi',
      'setAMECustomSettingSampleType',
      'setUmbrellaSettingRequiresValidationWithNewApi',
      'patchSettingRequiresValidationWithNewApi',
      'patchUmbrellaSettingRequiresValidationWithNewApi',
      'patchAMESettingRequiresValidationWithNewApi',
      'removeAMECountryCustomSettings',
      'patchSettingRowStatus',
      'clearSetting',
      'addCountrySetting',
      'addAMECountrySetting',
      'addAMECountryCustomSetting',
      'addCountryUmbrellaSetting',
      'removeCountrySetting',
      'removeAMECountrySetting',
      'setAMECustomSettingDocumentation',
      'removeUmbrellaCountrySetting',
      'setAMECountryCustomSettings',
      'setCustomSettingTumor',
      'setCustomSettingSampleType',
      'setCustomSettingDocumentation',
      'setUmbrellaCustomSettingTumor',
      'setUmbrellaCustomSettingSampleType',
      'setUmbrellaCustomSettingDocumentation',
      'setCountryUmbrellaCustomSettings',
      'addCountryCustomSetting',
      'addCountryUmbrellaCustomSetting',
      'clearCountryCustomSetting',
      'clearCountryUmbrellaCustomSetting',
      'setCountryCustomSettings',
      'removeCountryCustomSettings',
      'removeCountryUmbrellaCustomSettings',
      'setCountryRowStatus',
      'setUmbrellaSettingUmbrellaId',
      'setUmbrellaSettingPharma',
      'getCaps',
      'setCapsName',
      'setCaps',
      'setCapsInitialValue',
      'getAssociatedCaps',
      'saveCapsConfiguration',
      'setEmptyCaps',
      'deleteCapsConfig',
    ]),
    ...mapActions('biomarkers', ['getBiomarkers']),
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('pharmas', ['getPharmas']),
    ...mapActions('labs', ['getLabs']),
    ...mapActions('panels', ['getUmbrellas']),
    handleTimeChange(time) {
      this.timeUntilDismissed = time
    },
    saveCapsConfigurationAndSetMessage() {
      return this.saveCapsConfiguration().then((response) => {
        !this.hasErrors
          ? (this.msg = 'Operacion realizada con éxito')
          : (this.msg = `Ocurrio un error en el servidor ${response}`)
        this.timeUntilDismissed = this.duration
        this.getAssociatedCaps(this.$store.state.countries.caps.id)
      })
    },
    async handleSave(e) {
      if (e) e.preventDefault()
      await this.saveCountry()
      if (!this.hasErrors)
        this.$router.push({ name: this.NAMED_ROUTES.COUNTRIES })
    },
    handleDiscard() {
      this.$router.push({ name: this.NAMED_ROUTES.COUNTRIES })
    },
    getNameById(arr) {
      const obj = {}
      arr.forEach((el) => (obj[el.id] = el.name))
      return obj
    },
    handleToggleStatus() {
      const action = this.isEnabled ? 'deshabilitar' : 'habilitar'
      const result = window.confirm(`Al ${action} ${
        this.countryName
      } se ${action}án las configuraciones de biomarcadores para farmacéuticas que estén asociadas.

Los usuarios del país podrán acceder al sistema ${
        this.isEnabled ? 'pero no' : 'y'
      } podrán crear ${this.isEnabled ? 'ni' : 'y'} editar estudios.`)
      if (!result) return
      const newRowStatus = this.isEnabled ? 'D' : 'E'
      this.setCountryRowStatus(newRowStatus)
      this.handleSave()
    },
    handleOpenModal(index) {
      this.modal.settingIndex = index
      this.modal.tumorOptions = this.biomarkers
        .find(
          (biomarker) =>
            biomarker.id === this.countrySettings[index].biomarkerId
        )
        .primaryTumors.map((tumor) => ({ value: tumor.id, text: tumor.name }))
      // this.getCountrySetting(index)
      this.modal.show = true
    },
    async handleOpenCapsModal(setting, index) {
      this.msg = null
      await this.getCaps(setting.id).then(() => {
        this.getAssociatedCaps(this.$store.state.countries.caps.id)
        if (!this.$store.state.countries.caps.capsName) {
          let biomarker = this.biomarkersEnabled.find(
            (bmk) => bmk.id === setting.biomarkerId
          )
          let pharma = this.pharmasEnabled.find(
            (ph) => ph.id === setting.pharmaId
          )
          let country = this.$store.state.countries.country
          this.setCapsName(`${biomarker.code}_${pharma.code}_${country.name}`)
        }
      })
      this.capsModal.settingIndex = index
      this.capsModal.capsOptions = this.$store.state.countries.caps
      this.capsModal.show = true
    },
    handleOpenUmbrellaModal(index) {
      this.umbrellaModal.settingIndex = index
      this.umbrellaModal.tumorOptions = this.primaryTumorsEnabled.map(
        (tumor) => ({ value: tumor.id, text: tumor.name })
      )
      this.umbrellaModal.show = true
    },
    handleOpenAMEModal(index) {
      this.AMEModal.settingIndex = index
      this.AMEModal.show = true
    },
    handleCloseModal() {
      this.clearCountryCustomSetting()
      this.modal.show = false
      this.modal.settingIndex = -1
    },
    handleCloseAMEModal() {
      // this.clearCountryCustomSetting()
      this.AMEModal.show = false
      this.modal.settingIndex = -1
    },
    handleCloseUmbrellaModal() {
      this.clearCountryUmbrellaCustomSetting()
      this.umbrellaModal.show = false
      this.umbrellaModal.settingIndex = -1
    },
    isPrimaryTumorLocked(primaryTumorId) {
      return (
        this.doesPrimaryTumorHaveNullSettings(primaryTumorId) ||
        !this.sampleTypesOptionsForPrimaryTumor(primaryTumorId).length
      )
    },
    isUmbrellaPrimaryTumorLocked(primaryTumorId) {
      return (
        this.doesUmbrellaPrimaryTumorHaveNullSettings(primaryTumorId) ||
        !this.sampleTypesOptionsForUmbrellaPrimaryTumor(primaryTumorId).length
      )
    },
    allowNullSampleType(primaryTumorId) {
      return !!this.countrySettings[
        this.modal.settingIndex
      ].customSettings.find(
        (setting) =>
          setting.primaryTumorId === primaryTumorId &&
          setting.sampleTypeId !== null
      )
    },
    allowUmbrellaNullSampleType(primaryTumorId) {
      // No tiene mucho sentido la existencia de esta función y la anterior porque cuando seleccionarias el null?
      return !!this.countryUmbrellaSettings[
        this.umbrellaModal.settingIndex
      ].umbrellaCustomSetting.find(
        (setting) =>
          setting.primaryTumorId === primaryTumorId &&
          setting.sampleTypeId !== null
      )
    },
    isDiagnosisIsAlreadySelected() {
      let diagnosis = document.getElementById('diagnosisField')
        ? document.getElementById('diagnosisField').value
        : null
      let sampleTypeSelected = document.getElementById('sampleTypeBiomarker')
        ? document.getElementById('sampleTypeBiomarker').value
        : null
      let correctedSampleType =
        sampleTypeSelected === '' ? null : sampleTypeSelected
      let aBoll =
        diagnosis != null && sampleTypeSelected != null
          ? !!this.$store.state.countries.country.settings[
              this.modal.settingIndex
            ].customSettings.find(
              (cs) =>
                cs.diagnosis === diagnosis &&
                cs.sampleTypeId == correctedSampleType
            )
          : null
      aBoll !== true
        ? (this.diagnosisIsAlreadySelected = false)
        : (this.diagnosisIsAlreadySelected = true)
      return aBoll
    },
  },
}
</script>

<style lang="scss" scoped>
.header-2 {
  width: 100%;
  display: inline-flex;
  gap: 16px;
  grid-template-columns: 1fr;
  align-items: center;
}
.inline-checkboxs {
  display: inline-flex;
}

.setting .header,
.setting .result,
.custom .header,
.custom .result {
  width: 100%;
  display: inline-grid;
  gap: 16px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

.setting {
  .header > :nth-child(3) {
    display: none;
  }
  .result {
    grid-template-columns: repeat(2, 1fr);
    > :nth-child(-n + 3) {
      grid-column: span 2;
    }
    > :nth-child(n + 4) {
      margin: auto;
    }
  }
}

.custom {
  z-index: 1070;

  .header,
  .result {
    justify-items: center;
  }

  .result > :last-child {
    margin-bottom: 1rem;
  }

  select {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .setting .header,
  .setting .result {
    grid-template-columns: repeat(4, 1fr) 70px 70px;
  }
  .setting .header {
    > :nth-child(3) {
      font-size: 1rem;
      margin-top: 2rem;
      max-width: 4rem;
    }
    > :nth-child(4) {
      font-size: 0.95rem;
      margin-top: 2rem;
    }
  }
  .setting .result {
    > :nth-child(-n + 2) {
      grid-column: span 1;
    }
  }

  .custom .header > :nth-child(3) {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .setting .header,
  .setting .result {
    grid-template-columns: repeat(4, 1fr) 120px 250px;
  }
  .setting .header {
    > :nth-child(3) {
      display: block;
    }
  }
  .setting .result {
    > * {
      grid-column: span 1 !important;
    }
  }

  .custom .header,
  .custom .result {
    grid-template-columns: repeat(4, 1fr) 95px;
  }
}

.result button {
  transition: 15ms;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    transition: 15ms;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

select {
  height: 3.2rem !important;
}

.enabled {
  color: green;
}
.disabled {
  color: red;
}
</style>
