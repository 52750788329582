import axios from 'axios'

import { parse_pydantic_errors } from '@/utils'
import { ROW_STATUS } from '@/utils/constants'
const getEmptyCountryCaps = () => ({
  id: null,
  capsName: '',
  caps: null,
  userId: null,
  umbrellaTestPerPharmaPerCountryId: null,
  testPerPharmaPerCountryId: null,
  createdAt: null,
  updatedAt: null,
  numberOfAvailableTests: null,
  initialValue: null,
})
const getEmptyCountry = () => ({
  name: '',
  settings: [],
  countryUmbrellaSetting: [],
  defaultLabId: null,
})

const getEmptySetting = () => ({
  typeOfStudyId: null,
  biomarkerId: null,
  pharmaId: null,
  customSettings: [],
  requiresValidationWithNewApi: false,
  rowStatus: ROW_STATUS.ENABLED,
})

const getEmptyCustomSetting = () => ({
  primaryTumorId: null,
  sampleTypeId: null,
  documentation: null,
  diagnosis: null,
  customName: null,
})

const getEmptyUmbrellaSetting = () => ({
  typeOfStudyId: null,
  pharmaId: null,
  rowStatus: ROW_STATUS.ENABLED,
  umbrellaCustomSetting: [],
  umbrellaId: null,
  requiresValidationWithNewApi: false,
})
const getEmptyUmbrellaCustomSetting = () => ({
  primaryTumorId: null,
  sampleTypeId: null,
  documentation: null,
})

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  caps: getEmptyCountryCaps(),
  associatedCaps: {},
  countries: [],
  country: getEmptyCountry(),
  setting: getEmptySetting(),
  typeOfStudies: [],
  customSetting: getEmptyCustomSetting(),
  countryUmbrellaSetting: getEmptyUmbrellaSetting(),
  umbrellaCustomSetting: getEmptyUmbrellaCustomSetting(),
  sampleTypes: [],
  diagnosisOptions: [],
})

const getters = {
  caps: (state) => state.caps,
  associatedCaps: (state) => state.associatedCaps,
  countries: (state) => state.countries,
  countriesEnabled: (state) =>
    state.countries.filter(
      (country) => country.rowStatus === ROW_STATUS.ENABLED
    ),
  typeOfStudies: (state) => state.typeOfStudies,
  sampleTypes: (state) => state.sampleTypes,
  diagnosisOptions: (state) => state.diagnosisOptions,
  isCapsNew: (state) => !state.caps.id,
  isDirty: (state) => state.dirty,
  isValid: (state) => state.country.name && state.country.defaultLabId,
  isCustomSettingValid: (state) =>
    state.customSetting.primaryTumorId &&
    (state.customSetting.documentation || state.customSetting.customName),
  isUmbrellaCustomSettingValid: (state) =>
    state.umbrellaCustomSetting.primaryTumorId &&
    (state.umbrellaCustomSetting.sampleTypeId ||
      state.umbrellaCustomSetting.documentation),
  isNew: (state) =>
    !state.countries.map((country) => country.id).includes(state.country.id),
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}

const actions = {
  setSettingTypeOfStudyId({ commit }, value) {
    commit('SET_SETTING_TYPE_OF_STUDY_ID', value)
  },
  updateSettingTypeOfStudyId({ commit }, payload) {
    commit('UPDATE_SETTING_TYPE_OF_STUDY_ID', payload)
  },
  setUmbrellaSettingTypeOfStudyId({ commit }, value) {
    commit('SET_UMBRELLA_SETTING_TYPE_OF_STUDY_ID', value)
  },
  updateUmbrellaSettingTypeOfStudyId({ commit }, payload) {
    commit('UPDATE_UMBRELLA_SETTING_TYPE_OF_STUDY_ID', payload)
  },
  async getTypesOfStudy({ commit, state }) {
    if (state.status === 'loading') return
    try {
      let typeOfStudies = (await axios.get('/api2/settings/get_study_types'))
        .data
      commit('SET_TYPE_OF_STUDIES', typeOfStudies)
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async getCaps({ commit, state }, countrySettingId) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      let caps = (
        await axios.get(
          `/api2/caps/caps_for_biomarker_tests?country_setting_id=${countrySettingId}`
        )
      ).data
      if (caps.id === null) {
        caps = { ...caps }
        caps.testPerPharmaPerCountryId = countrySettingId
      }
      commit('SET_CAPS', caps)
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async getAssociatedCaps({ commit }, capId) {
    try {
      commit('REQUEST_INIT')
      let caps = (await axios.get(`/api2/caps/get_associated_cap/${capId}`))
        .data
      if (caps) commit('SET_ASSOCIATED_CAPS', caps)
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async saveCapsConfiguration({ commit, state, getters }) {
    try {
      commit('REQUEST_INIT')
      let method = getters.isCapsNew == true ? 'POST' : 'PATCH'
      let url =
        getters.isCapsNew == true
          ? '/api2/caps/create'
          : `/api2/caps/${state.caps.id}`

      let caps = (await axios({ method, url, data: { ...state.caps } })).data
      if (getters.isCapsNew == true) commit('SET_CAPS', caps)
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  setEmptyCaps({ commit }, id) {
    commit('SET_EMPTY_CAPS', id)
  },
  setCapsName({ commit }, name) {
    commit('SET_CAPS_NAME', name)
  },
  setCaps({ commit }, cap) {
    commit('SET_CAP', cap)
  },
  setCapsInitialValue({ commit }, intialValue) {
    commit('SET_CAPS_INITIAL_VALUE', intialValue)
  },
  deleteCapsConfig({ commit, state }) {
    try {
      commit('REQUEST_INIT')
      axios.delete(`/api2/caps/delete_caps/${state.caps.id}`)
      commit('SET_CAPS', getEmptyCountryCaps())
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async getDiagnosisOptions({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')

    try {
      const label = (await axios.get('/api2/patient/labels')).data
      commit('SET_DIAGNOSIS', label['diagnosis'])
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  setCustomSettingDiagnosis({ commit }, diagnosisOption) {
    commit('SET_DIAGNOSIS_OPTION', diagnosisOption)
  },
  setCustomSettingDiagnosisName({ commit }, name) {
    commit('SET_DIAGNOSIS_NAME', name)
  },
  async getSampleTypes({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const sampleTypes = (await axios.get('/api2/settings/sample_types/')).data
      commit('SET_SAMPLE_TYPES', sampleTypes)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async getCountries({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const countries = (await axios.get('/api2/country/')).data
      commit('SET_COUNTRIES', countries)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async getCountry({ commit }, countryId) {
    commit('REQUEST_INIT')
    try {
      const country = (await axios.get(`/api2/country/${countryId}`)).data
      if (country) commit('SET_COUNTRY', country)
      else commit('SET_COUNTRY', getEmptyCountry())
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQEST_ERROR', error)
    }
  },
  async saveCountry({ commit, state, getters }) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post' ? '/api2/country/' : `/api2/country/${state.country.id}`

    commit('REQUEST_INIT')
    try {
      const country = (
        await axios({
          method,
          url,
          data: { ...state.country },
        })
      ).data
      commit('REQUEST_SUCCESS')

      const countries = [...state.countries]
      if (method === 'post') {
        countries.push(country)
      } else {
        const index = countries.findIndex((c) => c.id === country.id)
        countries[index] = country
      }
      commit('SET_COUNTRIES', countries)
    } catch (err) {
      commit('REQUEST_ERROR', parse_pydantic_errors(err.response.data))
    }
  },
  setCountry({ commit, state }, countryId) {
    const country = state.countries.find((country) => country.id === countryId)
    if (country) commit('SET_COUNTRY', country)
    else commit('SET_COUNTRY', getEmptyCountry())
  },
  setCountryName({ commit }, name) {
    commit('SET_COUNTRY_NAME', name)
  },
  setSettingBiomarker({ commit }, biomarkerId) {
    commit('SET_SETTING_BIOMARKER', biomarkerId)
  },
  setSettingPharma({ commit }, pharmaId) {
    commit('SET_SETTING_PHARMA', pharmaId)
  },
  setSettingRequiresValidationWithNewApi({ commit }, value) {
    commit('SET_REQUIRES_VALIDATION_WITH_NEW_API', value)
  },
  setUmbrellaSettingRequiresValidationWithNewApi({ commit }, value) {
    commit('SET_UMBRELLA_REQUIRES_VALIDATION_WITH_NEW_API', value)
  },
  patchSettingRequiresValidationWithNewApi({ commit, state }, payload) {
    commit('REQUEST_INIT')
    try {
      axios
        .patch(
          `/api2/settings/patch_setting_envelope/${payload.settingId}/${payload.value}`
        )
        .then((response) => {
          if (response.status_code == 200) {
            commit('REQUEST_SUCCESS')
          }
        })
        .then(async () => {
          var newSettings = (
            await axios.get(
              `/api2/settings/get_country_settings/${state.country.id}`
            )
          ).data
          commit('UPDATE_COUNTRY_SETTING', [...newSettings])
          commit('REQUEST_SUCCESS')
        })
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  patchSettingRowStatus({ commit, state }, payload) {
    commit('REQUEST_INIT')
    try {
      axios
        .patch(
          `/api2/settings/patch_setting_row_status/${payload.settingId}/${payload.value}`
        )
        .then((response) => {
          if (response.status_code == 200) {
            commit('REQUEST_SUCCESS')
          }
        })
        .then(async () => {
          var newSettings = (
            await axios.get(
              `/api2/settings/get_country_settings/${state.country.id}`
            )
          ).data
          commit('UPDATE_COUNTRY_SETTING', [...newSettings])
          commit('REQUEST_SUCCESS')
        })
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  patchUmbrellaSettingRequiresValidationWithNewApi({ commit, state }, payload) {
    commit('REQUEST_INIT')
    try {
      axios
        .patch(
          `/api2/settings/patch_umbrella_setting_envelope/${payload.settingId}/${payload.value}`
        )
        .then((response) => {
          if (response.status_code == 200) {
            commit('REQUEST_SUCCESS')
          }
        })
        .then(async () => {
          var newSettings = (
            await axios.get(
              `/api2/settings/get_country_umbrella_settings/${state.country.id}`
            )
          ).data
          commit('UPDATE_UMBRELLA_COUNTRY_SETTING', [...newSettings])
          commit('REQUEST_SUCCESS')
        })
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  setUmbrellaSettingUmbrellaId({ commit }, umbrellaId) {
    commit('SET_UMBRELLA_SETTING_UMBRELLA_ID', umbrellaId)
  },
  setUmbrellaSettingPharma({ commit }, pharmaId) {
    commit('SET_UMBRELLA_SETTING_PHARMA', pharmaId)
  },
  clearSetting({ commit }) {
    commit('SET_SETTING', getEmptySetting())
  },
  addCountrySetting({ commit, state }) {
    commit('ADD_COUNTRY_SETTING', state.setting)
    commit('SET_SETTING', getEmptySetting())
  },
  addCountryUmbrellaSetting({ commit, state }) {
    commit('ADD_COUNTRY_UMBRELLA_SETTING', state.countryUmbrellaSetting)
    commit('SET_UMBRELLA_SETTING', getEmptyUmbrellaSetting())
  },
  removeCountrySetting({ commit, state }, setting) {
    let settingIndex = state.country.settings.findIndex(
      (s) =>
        s.biomarkerId === setting.biomarkerId && s.pharmaId === setting.pharmaId
    )
    commit('REMOVE_COUNTRY_SETTING', settingIndex)
  },
  removeUmbrellaCountrySetting({ commit, state }, setting) {
    let settingIndex = state.country.countryUmbrellaSetting.findIndex(
      (s) =>
        s.umbrellaId === setting.umbrellaId && s.pharmaId === setting.pharmaId
    )
    commit('REMOVE_UMBRELLA_COUNTRY_SETTING', settingIndex)
  },
  setCustomSettingTumor({ commit }, primaryTumorId) {
    commit('SET_CUSTOM_SETTING_TUMOR', primaryTumorId)
  },
  setCustomSettingSampleType({ commit }, sampleTypeId) {
    commit('SET_CUSTOM_SETTING_SAMPLE_TYPE', sampleTypeId)
  },
  setCustomSettingDocumentation({ commit }, documentation) {
    commit('SET_CUSTOM_SETTING_DOCUMENTATION', documentation)
  },
  setUmbrellaCustomSettingTumor({ commit }, primaryTumorId) {
    commit('SET_UMBRELLA_CUSTOM_SETTING_TUMOR', primaryTumorId)
  },
  setUmbrellaCustomSettingSampleType({ commit }, sampleTypeId) {
    commit('SET_UMBRELLA_CUSTOM_SETTING_SAMPLE_TYPE', sampleTypeId)
  },
  setUmbrellaCustomSettingDocumentation({ commit }, documentation) {
    commit('SET_UMBRELLA_CUSTOM_SETTING_DOCUMENTATION', documentation)
  },
  addCountryUmbrellaCustomSetting({ commit, state }, index) {
    commit('ADD_COUNTRY_UMBRELLA_CUSTOM_SETTING', {
      custom: state.umbrellaCustomSetting,
      index,
    })
    commit('SET_UMBRELLA_CUSTOM_SETTING', getEmptyUmbrellaCustomSetting())
  },
  addCountryCustomSetting({ commit, state }, index) {
    commit('ADD_COUNTRY_CUSTOM_SETTING', { custom: state.customSetting, index })
    commit('SET_CUSTOM_SETTING', getEmptyCustomSetting())
  },
  clearCountryCustomSetting({ commit }) {
    commit('SET_CUSTOM_SETTING', getEmptyCustomSetting())
  },
  clearCountryUmbrellaCustomSetting({ commit }) {
    commit('SET_UMBRELLA_CUSTOM_SETTING', getEmptyUmbrellaCustomSetting())
  },
  removeCountryCustomSettings({ commit }, payload) {
    commit('REMOVE_COUNTRY_SETTINGS_CUSTOM', payload)
  },
  removeCountryUmbrellaCustomSettings({ commit }, payload) {
    commit('REMOVE_COUNTRY_UMBRELLA_SETTINGS_CUSTOM', payload)
  },
  getCountrySetting({ commit, state }, index) {
    let setting = state.country.settings[index]
    if (setting) commit('SET_COUNTRY_SETTING', setting)
    else commit('SET_COUNTRY_SETTING', getEmptySetting())
  },
  setCountryCustomSettings({ commit }, payload) {
    commit('SET_COUNTRY_SETTINGS_CUSTOM', payload)
  },

  setCountryRowStatus({ commit }, value) {
    commit('SET_COUNTRY_ROW_STATUS', value)
  },
  setCountryDefaultLab({ commit }, defaultLabId) {
    commit('SET_COUNTRY_DEFAULT_LAB', defaultLabId)
  },
  setCountryUmbrellaCustomSettings({ commit }, payload) {
    commit('SET_COUNTRY_UMBRELLA_SETTINGS_CUSTOM', payload)
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  SET_TYPE_OF_STUDIES(state, val) {
    state.typeOfStudies = val
  },
  SET_SETTING_TYPE_OF_STUDY_ID(state, val) {
    state.setting.typeOfStudyId = val
  },

  UPDATE_SETTING_TYPE_OF_STUDY_ID(state, payload) {
    let newSettings = [...state.country.settings]
    let settingIndex = newSettings.findIndex(
      (s) =>
        s.biomarkerId == payload.setting.biomarkerId &&
        s.countryId == payload.setting.countryId &&
        s.pharmaId == payload.setting.pharmaId
    )
    let newSetting = newSettings[settingIndex]

    newSetting.typeOfStudyId = payload.newValue
    newSettings.splice(settingIndex, 1, newSetting)
    state.country.settings = [...newSettings]
  },
  UPDATE_UMBRELLA_SETTING_TYPE_OF_STUDY_ID(state, payload) {
    let newSettings = [...state.country.countryUmbrellaSetting]
    let settingIndex = newSettings.findIndex(
      (s) =>
        s.umbrellaId == payload.setting.umbrellaId &&
        s.countryId == payload.setting.countryId &&
        s.pharmaId == payload.setting.pharmaId
    )
    let newSetting = newSettings[settingIndex]

    newSetting.typeOfStudyId = payload.newValue
    newSettings.splice(settingIndex, 1, newSetting)
    state.country.countryUmbrellaSetting = [...newSettings]
  },
  SET_UMBRELLA_SETTING_TYPE_OF_STUDY_ID(state, val) {
    state.countryUmbrellaSetting.typeOfStudyId = val
  },
  SET_CAPS_INITIAL_VALUE(state, value) {
    state.caps.initialValue = value
  },
  SET_ASSOCIATED_CAPS(state, caps) {
    state.associatedCaps = caps
  },
  SET_CAPS(state, caps) {
    state.caps = caps
  },
  SET_CAPS_NAME(state, name) {
    state.caps.capsName = name
  },
  SET_CAP(state, cap) {
    state.caps.caps = cap
  },
  SET_EMPTY_CAPS(state, id) {
    state.caps = getEmptyCountryCaps()
    state.caps.testPerPharmaPerCountryId = id
  },
  SET_SAMPLE_TYPES(state, sampleTypes) {
    state.sampleTypes = sampleTypes
  },
  SET_DIAGNOSIS(state, diagnosis) {
    state.diagnosisOptions = diagnosis
  },
  SET_DIAGNOSIS_OPTION(state, diagnosisOption) {
    state.customSetting.diagnosis = diagnosisOption
  },
  SET_DIAGNOSIS_NAME(state, name) {
    state.customSetting.customName = name
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_COUNTRY(state, country) {
    // deep copy data without memory refs
    state.country = JSON.parse(JSON.stringify(country))
  },
  SET_COUNTRY_NAME(state, name) {
    state.dirty = true
    state.country.name = name
  },
  SET_REQUIRES_VALIDATION_WITH_NEW_API(state, value) {
    state.dirty = true
    state.setting.requiresValidationWithNewApi = value
  },

  SET_UMBRELLA_REQUIRES_VALIDATION_WITH_NEW_API(state, value) {
    state.dirty = true
    state.countryUmbrellaSetting.requiresValidationWithNewApi = value
  },
  SET_SETTING(state, setting) {
    state.dirty = true
    state.setting = setting
  },

  SET_SETTING_BIOMARKER(state, biomarkerId) {
    state.dirty = true
    state.setting.biomarkerId = biomarkerId
  },
  SET_SETTING_PHARMA(state, pharmaId) {
    state.dirty = true
    state.setting.pharmaId = pharmaId
  },

  SET_UMBRELLA_SETTING_UMBRELLA_ID(state, umbrellaId) {
    state.dirty = true
    state.countryUmbrellaSetting.umbrellaId = umbrellaId
  },
  SET_UMBRELLA_SETTING_PHARMA(state, pharmaId) {
    state.dirty = true
    state.countryUmbrellaSetting.pharmaId = pharmaId
  },
  SET_UMBRELLA_SETTING(state, umbrellaSetting) {
    state.dirty = true
    state.countryUmbrellaSetting = umbrellaSetting
  },
  ADD_COUNTRY_SETTING(state, setting) {
    state.dirty = true
    state.country.settings = [setting, ...state.country.settings]
  },

  ADD_COUNTRY_UMBRELLA_SETTING(state, setting) {
    state.dirty = true
    state.country.countryUmbrellaSetting = [
      setting,
      ...state.country.countryUmbrellaSetting,
    ]
  },
  REMOVE_COUNTRY_SETTING(state, settingIdx) {
    state.dirty = true
    state.country.settings.splice(settingIdx, 1)
  },

  REMOVE_UMBRELLA_COUNTRY_SETTING(state, settingIdx) {
    state.dirty = true
    state.country.countryUmbrellaSetting.splice(settingIdx, 1)
  },
  SET_CUSTOM_SETTING(state, customSetting) {
    state.dirty = true
    state.customSetting = customSetting
  },

  SET_CUSTOM_SETTING_TUMOR(state, primaryTumorId) {
    state.dirty = true
    state.customSetting.primaryTumorId = primaryTumorId
  },
  SET_CUSTOM_SETTING_SAMPLE_TYPE(state, sampleTypeId) {
    state.dirty = true
    state.customSetting.sampleTypeId = sampleTypeId
  },

  SET_CUSTOM_SETTING_DOCUMENTATION(state, documentation) {
    state.dirty = true
    state.customSetting.documentation = documentation
  },

  SET_UMBRELLA_CUSTOM_SETTING_TUMOR(state, primaryTumorId) {
    state.dirty = true
    state.umbrellaCustomSetting.primaryTumorId = primaryTumorId
  },
  SET_UMBRELLA_CUSTOM_SETTING_SAMPLE_TYPE(state, sampleTypeId) {
    state.dirty = true
    state.umbrellaCustomSetting.sampleTypeId = sampleTypeId
  },
  SET_UMBRELLA_CUSTOM_SETTING_DOCUMENTATION(state, documentation) {
    state.dirty = true
    state.umbrellaCustomSetting.documentation = documentation
  },
  SET_UMBRELLA_CUSTOM_SETTING(state, umbrellaCustomSetting) {
    state.dirty = true
    state.umbrellaCustomSetting = umbrellaCustomSetting
  },
  ADD_COUNTRY_CUSTOM_SETTING(state, { custom, index }) {
    state.dirty = true
    state.country.settings[index].customSettings = [
      custom,
      ...state.country.settings[index].customSettings,
    ]
  },

  ADD_COUNTRY_UMBRELLA_CUSTOM_SETTING(state, { custom, index }) {
    state.dirty = true
    state.country.countryUmbrellaSetting[index].umbrellaCustomSetting = [
      custom,
      ...state.country.countryUmbrellaSetting[index].umbrellaCustomSetting,
    ]
  },
  REMOVE_COUNTRY_SETTINGS_CUSTOM(state, { settingIndex, index }) {
    state.dirty = true
    const customSettings = [
      ...state.country.settings[settingIndex].customSettings,
    ]
    customSettings.splice(index, 1)
    state.country.settings[settingIndex].customSettings = customSettings
  },

  SET_COUNTRY_SETTING(state, setting) {
    state.dirty = true
    state.setting = setting
  },
  UPDATE_COUNTRY_SETTING(state, setting) {
    state.dirty = true
    state.country.settings = setting
  },

  UPDATE_UMBRELLA_COUNTRY_SETTING(state, setting) {
    state.dirty = true
    state.country.countryUmbrellaSetting = setting
  },
  SET_COUNTRY_SETTINGS_CUSTOM(state, { settingIndex, index, key, value }) {
    state.dirty = true
    const customSettings = [
      ...state.country.settings[settingIndex].customSettings,
    ]
    customSettings[index][key] = value
    state.country.settings[settingIndex].customSettings = customSettings
  },

  SET_COUNTRY_ROW_STATUS(state, value) {
    state.dirty = true
    state.country.rowStatus = value
  },
  SET_COUNTRY_DEFAULT_LAB(state, defaultLabId) {
    state.dirty = true
    state.country.defaultLabId = defaultLabId
  },
  SET_COUNTRY_UMBRELLA_SETTINGS_CUSTOM(
    state,
    { settingIndex, index, key, value }
  ) {
    state.dirty = true
    const umbrellaCustomSetting = [
      ...state.country.countryUmbrellaSetting[settingIndex]
        .umbrellaCustomSetting,
    ]
    umbrellaCustomSetting[index][key] = value
    state.country.countryUmbrellaSetting[settingIndex].umbrellaCustomSetting =
      umbrellaCustomSetting
  },
  REMOVE_COUNTRY_UMBRELLA_SETTINGS_CUSTOM(state, { settingIndex, index }) {
    state.dirty = true
    const umbrellaCustomSettings = [
      ...state.country.countryUmbrellaSetting[settingIndex]
        .umbrellaCustomSetting,
    ]
    umbrellaCustomSettings.splice(index, 1)
    state.country.countryUmbrellaSetting[settingIndex].umbrellaCustomSetting =
      umbrellaCustomSettings
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
