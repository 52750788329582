<template>
  <div>
    <d-card class="card-small mb-4" :class="{ disabled }">
      <d-card-header class="border-bottom">
        <h6 class="m-0">
          {{ principalLabel ? principalLabel : 'Punto de Retiro' }}
        </h6>
      </d-card-header>

      <d-card-body class="py-0">
        <!--
        <div
          v-if="!valid"
          class="alert alert-danger mt-3"
        >
          Elija un punto de retiro.
        </div>
-->

        <d-list-group flush class="list-group-small list-selection">
          <div
            v-for="(item, i) in available_items"
            :key="item.id"
            :class="getItemClass(item)"
            @click.stop.prevent="selectItem(item, i)"
          >
            <d-list-group-item class="d-flex row px-0">
              <d-col>
                <h6 class="go-stats__label mb-1">
                  {{ item.name }}
                </h6>
                <div class="go-stats__meta">
                  <span>
                    <strong>{{ item.address_1 }}</strong> {{ item.address_2 }}
                    <strong v-if="item.sector"
                      >&mdash; {{ item.sector }}</strong
                    >
                    <br />
                    <span v-if="item.county">{{ item.county }}, </span>
                    {{ item.state }}<br />
                    <strong>{{ item.telephone_1 }}</strong>
                    <div v-if="item.additional_information">
                      {{ item.additional_information }}
                    </div>
                  </span>
                </div>
              </d-col>
            </d-list-group-item>
          </div>
        </d-list-group>
      </d-card-body>
    </d-card>
  </div>
</template>
<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'

export default {
  name: 'PickupLocationPicker',
  mixins: [PlutonBaseMixin],
  props: {
    valid: {
      type: Boolean,
      required: false,
    },
    // eslint-disable-next-line vue/prop-name-casing
    doctor_id: {
      type: Number,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    principalLabel: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      current_value: this.value,
      available_items: [],
    }
  },
  mounted() {
    this.selectItem(undefined)
    let url = '/api/pickup-location'
    if (this.doctor_id) url = `${url}?doctor_id=${this.doctor_id}`

    this.axios.get(url).then((response) => {
      if (!response.data.success) return
      this.available_items = response.data.rows
      this.available_items.push({
        id: null,
        name: 'Otro',
        address_2:
          'Indicar Dirección completa, Teléfono y otros datos en "Comentarios Adicionales"',
      })
    })
  },
  methods: {
    getItemClass(item) {
      if (item.id === this.current_value) return 'list-group-item-active'
    },
    selectItem(item) {
      if (item != undefined) {
        this.current_value = item.id
        this.$emit('input', item.id)
        this.$emit('change', item.id)
      } else {
        this.current_value = undefined
        this.$emit('reset')
        this.$emit('input', undefined)
        this.$emit('change', undefined)
      }
    },
    isValid() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
  },
}
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.list-selection {
  margin-left: 2px;
  border-radius: 0 !important;
}

.list-selection .list-group-item {
  cursor: pointer;
}

.list-group-item-active {
  background-color: #6a44f7;
}

.list-group-item-active * {
  color: #fff !important;
}

.list-group-item {
  border-radius: 0;
}

.list-group-item {
  background-color: inherit;
}
</style>
